import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AdministrationService } from '../../../cms/desk-administration/agencies/administration/administration.service';
import { AutocompleteService } from 'piwe-front-swagger-client';
import { InputType as TransformingInputType } from '../transforming-input/transforming-input.component';
import { SnackBarService } from 'src/app/share/snack-bar.service';

export enum InputType {
  Text = 'text',
  Select = 'select',
  SelectNumeric = 'select_numeric',
  Autocomplete = 'autocomplete',
  Number = 'number',
  Boolean = 'boolean',
  Date = 'date',
  Password = 'password',
  ImageUpload = 'img_upload',
}

export enum FormType {
  // agencies
  General = 'general',
  PricesAndContracts = 'pricesAndContracts',
  Settings = 'settings',

  // companies
  CompaniesData = 'companiesData',
  CompanyInvoice = 'companyInvoice',
  CompanyPermissions = 'companyPermissions',
  UserData = 'userData',

  // finances
  DownloadsProductData = 'downloadsProductData',
  LumpSum = 'lumpSum',
  FinancesInvoices = 'financesInvoices',
  FinancesSapTaxIndicators = 'financesSapTaxIndicators',

  // internal user administration
  InternalUsers = 'internalUsers',
}

@Component({
  selector: 'app-desk-administration-transforming-forms',
  templateUrl: './desk-administration-transforming-forms.component.html',
  styleUrls: ['./desk-administration-transforming-forms.component.scss'],
})
export class DeskAdministrationTransformingFormsComponent implements OnInit {
  public InputType = InputType;
  public TransformingInputType = TransformingInputType;
  @Input() formType: FormType = FormType.General;
  @Input() form!: FormGroup;
  @Input() downloadRightsForm!: FormGroup;
  @Input() data$: any = {};
  @Output() saveEvent: EventEmitter<any> = new EventEmitter();
  @Output() deleteEvent: EventEmitter<any> = new EventEmitter();
  @Input() hasClearLogin = false;
  @Output() clearLoginEvent: EventEmitter<any> = new EventEmitter();
  @Input() deleteWarningText = '';
  @Input() deleteModalTitle = '';
  @Input() hasDelete = false;

  // number of times a route gets popped when canceling in a /new page
  // i.e. /cms/contracts-and-users/companies/new/data with popNum at 2 => /cms/contracts-and-users/companies
  @Input() cancelNewRoutePopAmount = 2;

  @Input() isUserInsideOfCompany = false;

  @Input()
  public isEditModeOn = false;
  @Output() isEditModeOnChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  private previousFormData: any = {};
  private dataSubscription: Subscription = Subscription.EMPTY;

  private paramsSubscription: Subscription = new Subscription();
  public params: any = {};
  @Input()
  public isNew = false;

  public countries: { [key: number]: string } = {};
  public currencies: { [key: number]: string } = {};
  public currenciesValues: { [key: string]: string } = {};
  public userGroups: { [key: number]: string } = {};

  @Input() contractItemsOptions: { [key: number]: string } = {};

  public isDeleteModalOpen = false;

  constructor(
    private administrationService: AdministrationService,
    private autocompleteService: AutocompleteService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    this.currenciesValues = {
      HRK: 'HRK',
      EUR: 'EUR',
    };

    this.paramsSubscription = this.route.parent!.params.subscribe((params) => {
      this.params = params;
      if (params.id === 'new') {
        this.isEditModeOn = true;
        this.isEditModeOnChange.emit(true);
        this.isNew = true;
      }
    });

    this.administrationService.agencyData$.pipe(take(1)).subscribe((data) => {
      this.initForm(this.administrationService.agencyData);
    });
    this.initForm(this.administrationService.agencyData);

    this.autocompleteService
      .cmsGetAutocompleteUserGroupByTitle('', 'HR')
      .subscribe((userGroups) => {
        userGroups.forEach((c) => {
          this.userGroups[c.id] = c.title;
        });
      });

    this.autocompleteService
      .cmsGetAutocompleteCountryByTitle('', 'HR')
      .subscribe((countries) => {
        countries.forEach((c) => {
          this.countries[c.id] = c.title;
        });
      });

    this.autocompleteService
      .cmsGetAutocompleteCurrencyByTitle('', 'HR')
      .subscribe((currencies) => {
        currencies.forEach((c) => {
          this.currencies[c.id] = c.title;
        });
      });
  }

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
  }

  openEditMode() {
    this.previousFormData = this.form.value;
    this.isEditModeOn = true;
    this.isEditModeOnChange.emit(true);
  }

  closeEditMode() {
    this.initForm(this.previousFormData);
    this.isEditModeOn = false;
    this.isEditModeOnChange.emit(false);
  }

  saveChanges() {
    this.form.markAsDirty();
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    if (this.isNew) {
      if (this.formType === FormType.General) {
        this.administrationService.agencyData = {
          ...this.administrationService.agencyData,
          ...this.form.value,
        };
      } else {
        this.saveEvent.emit(this.form.value);
        this.isEditModeOn = false;
        this.isEditModeOnChange.emit(false);
      }
    } else {
      this.saveEvent.emit(this.form.value);
      this.isEditModeOn = false;
      this.isEditModeOnChange.emit(false);
    }
  }

  // FORM
  ///////////////////////
  initForm(data: any) {
    // populate the form with the data from the api fetch
    for (const property in data) {
      if (this.form.get(property)) {
        this.form.controls[property].setValue(data[property]);
      }
    }

    if (this.formType == FormType.UserData) {
    }
  }

  // same buttons in iptc component
  saveNewAgency() {
    // Save new agency is only for agency creation, not companies.
    if (
      this.formType != FormType.General &&
      this.formType != FormType.PricesAndContracts &&
      this.formType != FormType.Settings
    ) {
      this.saveChanges();
      return;
    }

    this.form.markAsDirty();
    this.form.markAllAsTouched();

    if (this.form.valid) {
      switch (this.formType) {
        case FormType.General:
          this.administrationService.generalValidity = true;
          break;
        case FormType.PricesAndContracts:
          this.administrationService.priceAndContractsValidity = true;
          break;
        case FormType.Settings:
          this.administrationService.settingsValidity = true;
          break;
      }

      // current one set as valid, now check the rest
      const validityObject = this.administrationService.formValidityObject;
      if (!validityObject.isGeneralValid) {
        this.router.navigate(['general'], { relativeTo: this.route.parent });
        return;
      }
      if (!validityObject.isPriceAndContractsValid) {
        this.router.navigate(['prices-and-contracts'], {
          relativeTo: this.route.parent,
        });
        return;
      }
      if (!validityObject.isIptcValid) {
        this.router.navigate(['iptc'], { relativeTo: this.route.parent });
        return;
      }
      if (!validityObject.isSettingsValid) {
        this.router.navigate(['settings'], { relativeTo: this.route.parent });
        return;
      }
      // if program reaches here, everything is valid
      // post data
      this.administrationService.postNewAgency();
      this.cancelNewCreation();
    }
  }

  cancelNewCreation() {
    let routing = '';

    for (let i = 0; i < this.cancelNewRoutePopAmount; i++) {
      routing += '../';
    }

    this.router.navigate([routing], {
      relativeTo: this.route,
    });
  }

  handleDelete() {
    this.deleteEvent.emit();
    this.closeDeleteModal();
  }

  openDeleteModal() {
    this.isDeleteModalOpen = true;
  }

  closeDeleteModal() {
    this.isDeleteModalOpen = false;
  }

  clearLoginAttempts() {
    this.clearLoginEvent?.emit();
  }
}
