<div class="company-data">
  <ng-container *ngIf="!isNew">
    <div class="company-data__edit-container" *ngIf="!isEditModeOn">
      <button class="company-data__edit" (click)="openEditMode()">
        <img src="/assets/pencil.svg" alt="" />
        <div>Edit</div>
      </button>
      <button class="company-data__edit" (click)="clearLoginAttempts()" *ngIf="hasClearLogin">
        <div>Clear Login Attempts</div>
      </button>
      <button
        *ngIf="hasDelete"
        class="company-data__edit"
        (click)="openDeleteModal()"
      >
        <img src="/assets/trash.svg" alt="" />
        <div>Delete</div>
      </button>
    </div>

    <div class="company-data__edit-container" *ngIf="isEditModeOn">
      <button class="btn-save" (click)="saveChanges()">Save</button>
      <button class="btn-cancel" (click)="closeEditMode()">Cancel</button>
    </div>
  </ng-container>

  <ng-container *ngIf="isNew">
    <div class="company-data__edit-container">
      <button class="btn-save" (click)="saveNewAgency()">Save</button>
      <button class="btn-cancel" (click)="cancelNewCreation()">Cancel</button>
    </div>
  </ng-container>

  <div [ngSwitch]="formType" *ngIf="form">
    <ng-container *ngSwitchCase="'general'">
      <ng-container *ngTemplateOutlet="general"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'pricesAndContracts'">
      <ng-container *ngTemplateOutlet="pricesAndContracts"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'settings'">
      <ng-container *ngTemplateOutlet="settings"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'companiesData'">
      <ng-container *ngTemplateOutlet="companiesData"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'companyPermissions'">
      <ng-container *ngTemplateOutlet="companyPermissions"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'companyInvoice'">
      <ng-container *ngTemplateOutlet="companyInvoice"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'userData'">
      <ng-container *ngTemplateOutlet="userData"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'downloadsProductData'">
      <ng-container *ngTemplateOutlet="downloadsProductData"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'lumpSum'">
      <ng-container *ngTemplateOutlet="lumpSum"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'financesInvoices'">
      <ng-container *ngTemplateOutlet="financesInvoices"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'financesSapTaxIndicators'">
      <ng-container *ngTemplateOutlet="financesSapTaxIndicators"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'internalUsers'">
      <ng-container *ngTemplateOutlet="internalUsers"></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #general>
  <div class="company-data__header">
    <div>
      <h2>General information</h2>
      <div class="company-data__left">
        <div class="input-column">
          <app-transforming-input
            [label]="'Agency name'"
            [controlName]="'agency_name'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'Slug'"
            [controlName]="'slug'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'E-mail'"
            [controlName]="'email'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'Web'"
            [controlName]="'web'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
        </div>
        <div class="input-column">
          <app-transforming-input
            [label]="'Street and number'"
            [controlName]="'street_and_number'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'Zip'"
            [controlName]="'zip'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'City'"
            [controlName]="'city'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'country'"
            [controlName]="'country'"
            [form]="form"
            [type]="InputType.Select"
            [selectOptions]="countries"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
        </div>
      </div>
    </div>

    <div>
      <h2>Contact information</h2>
      <div class="company-data__right" class="input-column">
        <app-transforming-input
          [label]="'Contact person'"
          [controlName]="'contact_person'"
          [form]="form"
          [isEditModeActive]="isEditModeOn"
        ></app-transforming-input>
        <app-transforming-input
          [label]="'Contact number'"
          [controlName]="'contact_number'"
          [form]="form"
          [isEditModeActive]="isEditModeOn"
        ></app-transforming-input>
        <app-transforming-input
          [label]="'Contact e-mail'"
          [controlName]="'contact_email'"
          [form]="form"
          [isEditModeActive]="isEditModeOn"
        ></app-transforming-input>
        <app-transforming-input
          [label]="'Note'"
          [controlName]="'note'"
          [form]="form"
          [isEditModeActive]="isEditModeOn"
        ></app-transforming-input>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #pricesAndContracts>
  <div class="company-data__header">
    <div>
      <h2>Prices</h2>
      <div class="company-data__right" class="input-column">
        <app-transforming-input
          [label]="'Basic price large/print'"
          [controlName]="'var_large_price'"
          [form]="form"
          [isEditModeActive]="isEditModeOn"
        ></app-transforming-input>
        <app-transforming-input
          [label]="'BAsic price small/web'"
          [controlName]="'var_small_price'"
          [form]="form"
          [isEditModeActive]="isEditModeOn"
        ></app-transforming-input>
        <app-transforming-input
          [label]="'Currency'"
          [controlName]="'currency'"
          [form]="form"
          [type]="InputType.Select"
          [selectOptions]="currenciesValues"
          [isEditModeActive]="isEditModeOn"
        ></app-transforming-input>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #settings>
  <div class="company-data__header">
    <div class="company-data__right" class="input-column">
      <app-transforming-input
        [label]="'Active'"
        [controlName]="'is_active'"
        [form]="form"
        [type]="InputType.Boolean"
        [selectOptions]="{ true: 'Yes', false: 'No' }"
        [isEditModeActive]="isEditModeOn"
      ></app-transforming-input>
      <app-transforming-input
        [label]="'Public'"
        [controlName]="'is_public'"
        [form]="form"
        [type]="InputType.Boolean"
        [selectOptions]="{ true: 'Yes', false: 'No' }"
        [isEditModeActive]="isEditModeOn"
      ></app-transforming-input>
      <app-transforming-input
        [label]="'Visible in RSS'"
        [controlName]="'is_visible_in_rss'"
        [form]="form"
        [type]="InputType.Boolean"
        [selectOptions]="{ true: 'Yes', false: 'No' }"
        [isEditModeActive]="isEditModeOn"
      ></app-transforming-input>
      <app-transforming-input
        [label]="'Position in RSS'"
        [controlName]="'positon_in_rss'"
        [form]="form"
        [isEditModeActive]="isEditModeOn"
      ></app-transforming-input>
      <app-transforming-input
        [label]="'content type'"
        [controlName]="'content_type'"
        [form]="form"
        [type]="InputType.Select"
        [selectOptions]="{
          '': '',
          ILLUSTRATION: 'Illustration',
          PICTURE_VIDEO: 'Picture & Video',
          PICTURE: 'Picture',
          VIDEO: 'Video',
          ALL: 'All'
        }"
        [isEditModeActive]="isEditModeOn"
      ></app-transforming-input>
    </div>

    <div>
      <div class="company-data__right" class="input-column">
        <app-transforming-input
          [label]="'Import type'"
          [controlName]="'import_type'"
          [form]="form"
          [type]="InputType.Select"
          [selectOptions]="{
            '': '',
            AFP_REUTERS: 'AFP / REUTERS',
            AUTOMATIC: 'Automatic'
          }"
          [isEditModeActive]="isEditModeOn"
        ></app-transforming-input>
        <app-transforming-input
          [label]="'Agency folder name'"
          [controlName]="'agency_folder_name'"
          [form]="form"
          [isEditModeActive]="isEditModeOn"
        ></app-transforming-input>
        <app-transforming-input
          [label]="'Contains subfolders'"
          [controlName]="'contains_subfolder'"
          [form]="form"
          [type]="InputType.Boolean"
          [selectOptions]="{ true: 'Yes', false: 'No' }"
          [isEditModeActive]="isEditModeOn"
        ></app-transforming-input>
        <app-transforming-input
          [label]="'Agency subfolder name'"
          [controlName]="'agency_folder_name2'"
          [form]="form"
          [isEditModeActive]="isEditModeOn"
        ></app-transforming-input>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #companiesData>
  <div class="company-data__header">
    <div>
      <div class="company-data__left">
        <div class="input-column">
          <app-transforming-input
            *ngIf="!isNew"
            [label]="'ID'"
            [controlName]="'id'"
            [form]="form"
            [isEditModeActive]="false"
          ></app-transforming-input>
        </div>
        <div class="input-column"></div>
      </div>
    </div>
  </div>

  <br />
  <br />

  <div class="company-data__header">
    <div>
      <h2>General</h2>
      <div class="company-data__left">
        <div class="input-column">
          <app-transforming-input
            [label]="'Name'"
            [controlName]="'name'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'MBS'"
            [controlName]="'mbs'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'Note'"
            [controlName]="'note'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'OIB/VAT no.'"
            [controlName]="'oib_company'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'Language'"
            [controlName]="'lang'"
            [type]="InputType.Select"
            [selectOptions]="{ HR: 'Croatian', EN: 'English' }"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            *ngIf="!isNew"
            [label]="'Second step auth enabled'"
            [controlName]="'is_second_step_auth_enabled'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
            [type]="TransformingInputType.Boolean"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'History SH visible'"
            [controlName]="'is_history_sh_visible'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
            [type]="InputType.Boolean"
            [selectOptions]="{ true: 'Yes', false: 'No' }"
          ></app-transforming-input>
          <app-transforming-input
            *ngIf="!isNew"
            [label]="'Created at'"
            [controlName]="'created_at'"
            [form]="form"
            [isEditModeActive]="false"
            [type]="TransformingInputType.Date"
          ></app-transforming-input>
        </div>
      </div>
    </div>

    <div>
      <h2>Contact information</h2>
      <div>
        <div class="company-data__left">
          <div class="input-column">
            <app-transforming-input
              [label]="'Email'"
              [controlName]="'email'"
              [form]="form"
              [isEditModeActive]="isEditModeOn"
            ></app-transforming-input>
            <app-transforming-input
              [label]="'Address line 1'"
              [controlName]="'address_line_1'"
              [form]="form"
              [isEditModeActive]="isEditModeOn"
            ></app-transforming-input>
            <app-transforming-input
              [label]="'Postal number'"
              [controlName]="'postal_number'"
              [form]="form"
              [isEditModeActive]="isEditModeOn"
            ></app-transforming-input>
            <app-transforming-input
              [label]="'Contact email'"
              [controlName]="'contact_email'"
              [form]="form"
              [isEditModeActive]="isEditModeOn"
            ></app-transforming-input>
            <app-transforming-input
              [label]="'Contact phone'"
              [controlName]="'contact_phone'"
              [form]="form"
              [isEditModeActive]="isEditModeOn"
            ></app-transforming-input>
            <app-transforming-input
              [label]="'Press / Magazine name'"
              [controlName]="'press_magazine_name'"
              [form]="form"
              [isEditModeActive]="isEditModeOn"
            ></app-transforming-input>
          </div>
          <div class="input-column">
            <app-transforming-input
              [label]="'Phone'"
              [controlName]="'phone'"
              [form]="form"
              [isEditModeActive]="isEditModeOn"
            ></app-transforming-input>
            <app-transforming-input
              [label]="'City'"
              [controlName]="'city'"
              [form]="form"
              [isEditModeActive]="isEditModeOn"
            ></app-transforming-input>
            <app-transforming-input
              [label]="'country'"
              [controlName]="'country_id'"
              [form]="form"
              [type]="InputType.Select"
              [selectOptions]="countries"
              [isEditModeActive]="isEditModeOn"
            ></app-transforming-input>
            <app-transforming-input
              [label]="'Contact Person'"
              [controlName]="'contact_person'"
              [form]="form"
              [isEditModeActive]="isEditModeOn"
            ></app-transforming-input>
            <app-transforming-input
              [label]="'Contact User'"
              [autocompleteType]="'user'"
              [controlName]="'contact_user_id'"
              [placeholder]="form.get('contact_user_name')?.value"
              [type]="InputType.Autocomplete"
              [form]="form"
              [isEditModeActive]="isEditModeOn"
            ></app-transforming-input>
            <app-transforming-input
              [label]="'Fax'"
              [controlName]="'fax'"
              [form]="form"
              [isEditModeActive]="isEditModeOn"
            ></app-transforming-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #companyInvoice>
  <div class="company-data__header">
    <div>
      <div class="company-data__left">
        <div class="input-column">
          <app-transforming-input
            [label]="'Payment model'"
            [controlName]="'payment_model'"
            [type]="InputType.Select"
            [selectOptions]="{ '1': 'Post-paid', '2': 'Pre-paid' }"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'Don\'t create invoice'"
            [controlName]="'dont_create_invoice'"
            [type]="InputType.Boolean"
            [selectOptions]="{ true: 'Yes', false: 'No' }"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'Hide discounts'"
            [controlName]="'hide_discounts'"
            [type]="InputType.Boolean"
            [selectOptions]="{ true: 'Yes', false: 'No' }"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'Tax number'"
            [controlName]="'tax_number'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'VAT number'"
            [controlName]="'vat_number'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'SAP indicator'"
            [controlName]="'sap_indicator'"
            [type]="InputType.Select"
            [selectOptions]="{
              A0: 'A0',
              D1: 'D1',
              AY: 'AY',
              H3: 'H3',
              H2: 'H2'
            }"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
        </div>
        <div class="input-column">
          <app-transforming-input
            [label]="'Payment method'"
            [controlName]="'payment_method'"
            [type]="InputType.Select"
            [selectOptions]="{
              W: 'W',
              K: 'K',
              T: 'T'
            }"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'Currency'"
            [controlName]="'currency_id'"
            [type]="InputType.Select"
            [selectOptions]="currencies"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'Invoice text'"
            [controlName]="'invoice_text'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'Invoice sent at email'"
            [controlName]="'invoice_sent_at_email'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'Pixsell User ID'"
            [autocompleteType]="'user'"
            [controlName]="'pxl_user_id'"
            [placeholder]="form.get('pxl_user_name')?.value"
            [type]="InputType.Autocomplete"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'SAP Number'"
            [controlName]="'sap_number'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
        </div>
        <div class="input-column">
          <div class="company-data__left">
            <div class="input-column">
              <app-transforming-input
                [label]="'Payment due in days'"
                [controlName]="'payment_due_in_days'"
                [form]="form"
                [isEditModeActive]="isEditModeOn"
              ></app-transforming-input>
              <app-transforming-input
                [label]="'I/C'"
                [controlName]="'i_c'"
                [type]="InputType.Select"
                [selectOptions]="{ '0': 'No', '1': 'Yes' }"
                [form]="form"
                [isEditModeActive]="isEditModeOn"
              ></app-transforming-input>
              <app-transforming-input
                [label]="'Additional invoice text'"
                [controlName]="'additional_invoice_text'"
                [form]="form"
                [isEditModeActive]="isEditModeOn"
              ></app-transforming-input>
              <app-transforming-input
                [label]="'Invoice N/R'"
                [controlName]="'invoice_n_r'"
                [type]="InputType.Select"
                [selectOptions]="{ true: 'Yes', false: 'No' }"
                [form]="form"
                [isEditModeActive]="isEditModeOn"
              ></app-transforming-input>
              <app-transforming-input
                [label]="'Override contact person'"
                [controlName]="'override_contact_person'"
                [form]="form"
                [isEditModeActive]="isEditModeOn"
              ></app-transforming-input>
              <app-transforming-input
                [label]="'Override name'"
                [controlName]="'override_name'"
                [form]="form"
                [isEditModeActive]="isEditModeOn"
              ></app-transforming-input>
            </div>
            <div class="input-column"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #companyPermissions>
  <div>
    <div class="download-rights" [formGroup]="downloadRightsForm">
      <div>
        <h2>Download permissions</h2>
        <app-transforming-input
          [label]="'Block download of everything'"
          [controlName]="'are_downloads_blocked'"
          [type]="InputType.Boolean"
          [selectOptions]="{ true: 'Yes', false: 'No' }"
          [form]="form"
          [isEditModeActive]="isEditModeOn"
        ></app-transforming-input>
        <br />
        <br />

        <div class="download-rights__section">
          <div [class.box--unchecked]="false">
            <label class="box-header"> Photo </label>
            <div class="box-body">
              <label for="large" class="box-body-item">
                <label for="large" class="custom-checkbox">
                  <input
                    type="checkbox"
                    name="large"
                    id="large"
                    [attr.disabled]="!isEditModeOn ? true : null"
                    formControlName="permissions_photo_large"
                  />
                  <div></div>
                </label>
                <div>
                  <div
                    class="box-body-large"
                    [class.checked-text]="
                      downloadRightsForm.get('permissions_photo_large')?.value
                    "
                  >
                    LARGE
                  </div>
                  <div class="box-body-small">Print (x&#62;1600)</div>
                </div>
              </label>

              <label for="small" class="box-body-item">
                <label for="small" class="custom-checkbox">
                  <input
                    type="checkbox"
                    name="small"
                    id="small"
                    [attr.disabled]="!isEditModeOn ? true : null"
                    formControlName="permissions_photo_small"
                  />
                  <div></div>
                </label>
                <div>
                  <div
                    class="box-body-large"
                    [class.checked-text]="
                      downloadRightsForm.get('permissions_photo_small')?.value
                    "
                  >
                    SMALL
                  </div>
                  <div class="box-body-small">Web (x&#60;1600)</div>
                </div>
              </label>
            </div>
          </div>
          <div [class.box--unchecked]="false">
            <label for="video" class="box-header box-header--single">
              Video
              <label for="video" class="custom-checkbox">
                <input
                  type="checkbox"
                  name="video"
                  id="video"
                  [attr.disabled]="!isEditModeOn ? true : null"
                  formControlName="permissions_video"
                />

                <div></div>
              </label>
            </label>
          </div>
        </div>
      </div>

      <div [formGroup]="form">
        <h2>Downloads</h2>
        <div class="download-rights__section">
          <div [class.box--unchecked]="false">
            <label for="sendToSystem" class="box-header">
              Send to system
              <label for="sendToSystem" class="custom-checkbox">
                <input
                  type="checkbox"
                  name="sendToSystem"
                  id="sendToSystem"
                  [attr.disabled]="!isEditModeOn ? true : null"
                  formControlName="send_to_system"
                />
                <div></div>
              </label>
            </label>
            <div class="box-body box-big">
              <div>
                <app-transforming-input
                  [label]="'FTP path'"
                  [controlName]="'send_to_system_ftp_path'"
                  [form]="form"
                  [isEditModeActive]="isEditModeOn"
                ></app-transforming-input>
              </div>
            </div>
          </div>

          <div [class.box--unchecked]="false">
            <label for="sendToCms" class="box-header">
              Send to CMS
              <label for="sendToCms" class="custom-checkbox">
                <input
                  type="checkbox"
                  name="sendToCms"
                  id="sendToCms"
                  formControlName="send_to_cms"
                  [attr.disabled]="!isEditModeOn ? true : null"
                />
                <div></div>
              </label>
            </label>
            <div class="box-body box-big box-big2">
              <div>
                <app-transforming-input
                  [label]="'API Key'"
                  [controlName]="'send_to_cms_api_key'"
                  [form]="form"
                  [isEditModeActive]="isEditModeOn"
                ></app-transforming-input>
              </div>

              <hr class="box-hr" />

              <div>
                <app-transforming-input
                  [label]="'URL'"
                  [controlName]="'send_to_cms_url'"
                  [form]="form"
                  [isEditModeActive]="isEditModeOn"
                ></app-transforming-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #userData>
  <div class="input-column avatar">
    <app-transforming-input
      [label]="''"
      [controlName]="'avatar'"
      [form]="form"
      [type]="InputType.ImageUpload"
      [isEditModeActive]="isEditModeOn"
    ></app-transforming-input>
  </div>
  <div class="company-data__header">
    <div>
      <div class="company-data__left">
        <div *ngIf="!isNew" class="input-column">
          <app-transforming-input
            [label]="'ID'"
            [controlName]="'id'"
            [form]="form"
            [isEditModeActive]="false"
          ></app-transforming-input>
        </div>
        <div *ngIf="!isNew" class="input-column">
          <app-transforming-input
            [label]="'Username'"
            [controlName]="'username'"
            [form]="form"
            [isEditModeActive]="isNew && isEditModeOn"
          ></app-transforming-input>
        </div>
      </div>
    </div>
  </div>

  <br />
  <br />

  <div class="company-data__header">
    <div>
      <h2>General</h2>
      <div class="company-data__left">
        <div class="input-column">
          <app-transforming-input
            *ngIf="isNew"
            [label]="'Username'"
            [controlName]="'username'"
            [form]="form"
            [isEditModeActive]="isNew && isEditModeOn"
          ></app-transforming-input>

          <app-transforming-input
            [label]="'First name'"
            [controlName]="'first_name'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>

          <app-transforming-input
            [label]="'Full Name'"
            helpText="duplicated field, migrated from the old system"
            [controlName]="'name'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>

          <app-transforming-input
            *ngIf="!isNew"
            [label]="'Password'"
            [controlName]="'password'"
            [form]="form"
            [type]="InputType.Password"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
        </div>
        <div class="input-column">
          <app-transforming-input
            *ngIf="isNew"
            [label]="'Password'"
            [controlName]="'password'"
            [form]="form"
            [type]="InputType.Password"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>

          <app-transforming-input
            [label]="'Last name'"
            [controlName]="'last_name'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
        </div>
        <div class="input-column">
          <app-transforming-input
            [label]="'Email'"
            [controlName]="'email'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
        </div>
      </div>
      <br />
      <br />
      <div class="company-data__left">
        <div class="input-column">
          <app-transforming-input
            [label]="'State'"
            [controlName]="'state'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
            [type]="InputType.Select"
            [selectOptions]="{
              DELETED: 'Deleted',
              APPROVED: 'Approved',
              UNCONFIRMED_IDENTITY: 'Unconfirmed identity',
              PENDING_APPROVAL: 'Pending approval'
            }"
          ></app-transforming-input>

          <app-transforming-input
            *ngIf="!isUserInsideOfCompany || (isUserInsideOfCompany && !isNew)"
            [label]="'Company'"
            [controlName]="'company_id'"
            [form]="form"
            [placeholder]="form.get('company_name')?.value"
            [type]="InputType.Autocomplete"
            [autocompleteType]="'company'"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
        </div>
        <div class="input-column">
          <app-transforming-input
            *ngIf="!isUserInsideOfCompany || (isUserInsideOfCompany && !isNew)"
            [label]="'Kind'"
            [controlName]="'kind'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
            [type]="InputType.Select"
            [selectOptions]="{
              CORPORATE: 'Corporate',
              SMALL: 'Small'
            }"
          ></app-transforming-input>

          <app-transforming-input
            [label]="'Company Role'"
            [controlName]="'company_role'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
            [type]="InputType.Select"
            [selectOptions]="{
              OWNER: 'Owner',
              USER: 'User'
            }"
          ></app-transforming-input>
        </div>
      </div>
      <br />
      <br />
      <div class="company-data__left">
        <div class="input-column">
          <app-transforming-input
            [label]="'Agreement date'"
            [controlName]="'agreement_date'"
            [type]="InputType.Date"
            [form]="form"
            [isEditModeActive]="false"
          ></app-transforming-input>

          <app-transforming-input
            [label]="'GDPR confirmed date'"
            [controlName]="'gdpr_confirmed_date'"
            [type]="InputType.Date"
            [form]="form"
            [isEditModeActive]="false"
          ></app-transforming-input>
        </div>
        <div class="input-column">
          <app-transforming-input
            [label]="'metrics confirmed date'"
            [controlName]="'metrics_confirmed_date'"
            [type]="InputType.Date"
            [form]="form"
            [isEditModeActive]="false"
          ></app-transforming-input>

          <app-transforming-input
            [label]="'created at'"
            [controlName]="'created_at'"
            [type]="InputType.Date"
            [form]="form"
            [isEditModeActive]="false"
          ></app-transforming-input>
        </div>
      </div>
    </div>

    <div>
      <h2>Contact information</h2>
      <div>
        <div class="company-data__left">
          <div class="input-column" style="min-width: 50%">
            <app-transforming-input
              [label]="'city'"
              [controlName]="'city'"
              [form]="form"
              [isEditModeActive]="isEditModeOn"
            ></app-transforming-input>
          </div>
          <div class="input-column" style="min-width: 50%">
            <app-transforming-input
              [label]="'country'"
              [controlName]="'country_id'"
              [form]="form"
              [type]="InputType.Select"
              [selectOptions]="countries"
              [isEditModeActive]="isEditModeOn"
            ></app-transforming-input>
          </div>
        </div>
        <br />
        <div class="company-data__left">
          <div class="input-column">
            <app-transforming-input
              [label]="'phone number'"
              [controlName]="'phone_number'"
              [form]="form"
              [isEditModeActive]="isEditModeOn"
            ></app-transforming-input>

            <app-transforming-input
              [label]="'press / magazine name'"
              [controlName]="'press_magazine_name'"
              [form]="form"
              [isEditModeActive]="isEditModeOn"
            ></app-transforming-input>
          </div>
          <div class="input-column" style="min-width: 50%">
            <app-transforming-input
              [label]="'country'"
              [controlName]="'country_id'"
              [form]="form"
              [type]="InputType.Select"
              [selectOptions]="countries"
              [isEditModeActive]="isEditModeOn"
            ></app-transforming-input>
            <app-transforming-input
              [label]="'mobile number'"
              [controlName]="'mobile_number'"
              [form]="form"
              [isEditModeActive]="isEditModeOn"
            ></app-transforming-input>
          </div>
        </div>
      </div>
    </div>
  </div>

  <br />
  <br />

  <div class="company-data__header">
    <div>
      <h2>Permissions</h2>
      <div class="company-data__left">
        <div class="input-column">
          <app-transforming-input
            [label]="'Visible agencies'"
            [controlName]="'old_flag_can_see_image_only_agencies'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
            [type]="InputType.SelectNumeric"
            [selectOptions]="{
              '0': '--',
              '1': 'Reuters & AFP',
              '2': 'Reuters',
              '3': 'AFP'
            }"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'History SH visible'"
            [controlName]="'is_history_sh_visible'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
            [type]="InputType.Boolean"
            [selectOptions]="{ true: 'Yes', false: 'No' }"
          ></app-transforming-input>
        </div>
      </div>
    </div>
    <div>
      <div style="height: 90px"></div>
      <div class="company-data__left">
        <div class="input-column"></div>
      </div>
    </div>
  </div>

  <div>
    <div class="download-rights" [formGroup]="downloadRightsForm">
      <div>
        <h2>Download permissions</h2>
        <div class="download-rights__section">
          <div [class.box--unchecked]="false">
            <label class="box-header"> Photo </label>
            <div class="box-body">
              <label for="large" class="box-body-item">
                <label for="large" class="custom-checkbox">
                  <input
                    type="checkbox"
                    name="large"
                    id="large"
                    [attr.disabled]="!isEditModeOn ? true : null"
                    formControlName="permissions_photo_large"
                  />
                  <div></div>
                </label>
                <div>
                  <div
                    class="box-body-large"
                    [class.checked-text]="
                      downloadRightsForm.get('permissions_photo_large')?.value
                    "
                  >
                    LARGE
                  </div>
                  <div class="box-body-small">Print (x&#62;1600)</div>
                </div>
              </label>

              <label for="small" class="box-body-item">
                <label for="small" class="custom-checkbox">
                  <input
                    type="checkbox"
                    name="small"
                    id="small"
                    [attr.disabled]="!isEditModeOn ? true : null"
                    formControlName="permissions_photo_small"
                  />
                  <div></div>
                </label>
                <div>
                  <div
                    class="box-body-large"
                    [class.checked-text]="
                      downloadRightsForm.get('permissions_photo_small')?.value
                    "
                  >
                    SMALL
                  </div>
                  <div class="box-body-small">Web (x&#60;1600)</div>
                </div>
              </label>
            </div>
          </div>
          <div [class.box--unchecked]="false">
            <label for="video" class="box-header box-header--single">
              Video
              <label for="video" class="custom-checkbox">
                <input
                  type="checkbox"
                  name="video"
                  id="video"
                  [attr.disabled]="!isEditModeOn ? true : null"
                  formControlName="permissions_video"
                />

                <div></div>
              </label>
            </label>
          </div>
        </div>
      </div>

      <div [formGroup]="form">
        <h2>Downloads</h2>
        <div class="download-rights__section">
          <div [class.box--unchecked]="false">
            <label for="sendToSystem" class="box-header">
              Send to system
              <label for="sendToSystem" class="custom-checkbox">
                <input
                  type="checkbox"
                  name="sendToSystem"
                  id="sendToSystem"
                  [attr.disabled]="!isEditModeOn ? true : null"
                  formControlName="send_to_system"
                />
                <div></div>
              </label>
            </label>
            <div class="box-body box-big">
              <div>
                <app-transforming-input
                  [label]="'FTP path'"
                  [controlName]="'send_to_system_ftp_path'"
                  [form]="form"
                  [isEditModeActive]="isEditModeOn"
                ></app-transforming-input>
              </div>
            </div>
          </div>

          <div [class.box--unchecked]="false">
            <label for="sendToCms" class="box-header">
              Send to CMS
              <label for="sendToCms" class="custom-checkbox">
                <input
                  type="checkbox"
                  name="sendToCms"
                  id="sendToCms"
                  formControlName="send_to_cms"
                  [attr.disabled]="!isEditModeOn ? true : null"
                />
                <div></div>
              </label>
            </label>
            <div class="box-body box-big box-big2">
              <div>
                <app-transforming-input
                  [label]="'API Key'"
                  [controlName]="'send_to_cms_api_key'"
                  [form]="form"
                  [isEditModeActive]="isEditModeOn"
                ></app-transforming-input>
              </div>

              <hr class="box-hr" />

              <div>
                <app-transforming-input
                  [label]="'URL'"
                  [controlName]="'send_to_cms_url'"
                  [form]="form"
                  [isEditModeActive]="isEditModeOn"
                ></app-transforming-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #downloadsProductData>
  <div style="margin-bottom: 20px">
    <app-transforming-input
      [label]="'Product name'"
      [controlName]="'product_name'"
      [form]="form"
      [type]="InputType.Text"
      [isEditModeActive]="false"
    ></app-transforming-input>
  </div>

  <div class="company-data__header">
    <div>
      <div class="company-data__left">
        <div class="input-column">
          <div class="company-data__right" class="input-column">
            <app-transforming-input
              [label]="'Price'"
              [controlName]="'download_price'"
              [form]="form"
              [type]="InputType.Number"
              [isEditModeActive]="isEditModeOn"
            ></app-transforming-input>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="company-data__right" class="input-column">
        <app-transforming-input
          [label]="'Currency'"
          [controlName]="'currency'"
          [form]="form"
          [type]="InputType.Select"
          [selectOptions]="{ EUR: 'EUR', HRK: 'HRK' }"
          [isEditModeActive]="isEditModeOn"
        ></app-transforming-input>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #lumpSum>
  <div class="company-data__header">
    <div>
      <div class="company-data__left">
        <div class="input-column">
          <app-transforming-input
            [label]="'Contract item'"
            [controlName]="'contractItem'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'Month'"
            [controlName]="'month'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
        </div>
        <div class="input-column">
          <app-transforming-input
            [label]="'Downloaded'"
            [controlName]="'downloaded'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'Year'"
            [controlName]="'year'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
        </div>
      </div>
    </div>

    <div>
      <div class="company-data__right" class="input-column">
        <app-transforming-input
          [label]="'total'"
          [controlName]="'total'"
          [form]="form"
          [isEditModeActive]="isEditModeOn"
        ></app-transforming-input>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #financesInvoices>
  <div class="company-data__header">
    <div>
      <div class="company-data__left">
        <div class="input-column">
          <app-transforming-input
            [label]="'Type'"
            [controlName]="'type'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'due date'"
            [controlName]="'due_date'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'sap allowed'"
            [controlName]="'sap_allowed'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'approved by user (full name)'"
            [controlName]="'approved_by'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
        </div>
        <div class="input-column">
          <app-transforming-input
            [label]="'client'"
            [controlName]="'client'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'Invoice date'"
            [controlName]="'invoice_date'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'Comment'"
            [controlName]="'comment'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
        </div>
      </div>
    </div>

    <div>
      <div class="company-data__right" class="input-column">
        <app-transforming-input
          [label]="'currency'"
          [controlName]="'currency'"
          [form]="form"
          [isEditModeActive]="isEditModeOn"
        ></app-transforming-input>
        <app-transforming-input
          [label]="'Payment method'"
          [controlName]="'payment_method'"
          [form]="form"
          [isEditModeActive]="isEditModeOn"
        ></app-transforming-input>
        <app-transforming-input
          [label]="'ADDITIONAL TEXT THAT WILL BE ADDED TO INVOICE FOLDER'"
          [controlName]="'additional_text'"
          [form]="form"
          [isEditModeActive]="isEditModeOn"
        ></app-transforming-input>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #financesSapTaxIndicators>
  <div class="company-data__header">
    <div>
      <div class="company-data__left">
        <div class="input-column">
          <app-transforming-input
            [label]="'Code'"
            [controlName]="'code'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'Note HR'"
            [controlName]="'noteHR'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
        </div>
        <div class="input-column">
          <app-transforming-input
            [label]="'Tax rate'"
            [controlName]="'taxRate'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'Note En'"
            [controlName]="'noteEN'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
        </div>
      </div>
    </div>

    <div>
      <div class="company-data__right" class="input-column">
        <app-transforming-input
          [label]="'note De'"
          [controlName]="'noteDE'"
          [form]="form"
          [isEditModeActive]="isEditModeOn"
        ></app-transforming-input>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #internalUsers>
  <div class="company-data__header">
    <div>
      <div class="company-data__left">
        <div class="input-column">
          <app-transforming-input
            [label]="'Username'"
            [controlName]="'username'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'E-mail'"
            [controlName]="'email'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'Password'"
            [controlName]="'password'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'User status'"
            [controlName]="'user_status'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
        </div>
        <div class="input-column">
          <app-transforming-input
            [label]="'Name'"
            [controlName]="'name'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'Autologin id'"
            [controlName]="'autologin_id'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
          <app-transforming-input
            [label]="'Api key id'"
            [controlName]="'api_key_id'"
            [form]="form"
            [isEditModeActive]="isEditModeOn"
          ></app-transforming-input>
        </div>
      </div>
    </div>

    <div>
      <div class="company-data__right" class="input-column">
        <app-transforming-input
          [label]="'Input directory'"
          [controlName]="'input_directory'"
          [form]="form"
          [isEditModeActive]="isEditModeOn"
        ></app-transforming-input>
        <app-transforming-input
          [label]="'Save directory'"
          [controlName]="'save_directory'"
          [form]="form"
          [isEditModeActive]="isEditModeOn"
        ></app-transforming-input>
        <app-transforming-input
          [label]="'User group'"
          [controlName]="'user_group_id'"
          [form]="form"
          [type]="InputType.Select"
          [selectOptions]="userGroups"
          [isEditModeActive]="isEditModeOn"
        ></app-transforming-input>
      </div>
    </div>
  </div>
</ng-template>

<app-modal
  *ngIf="isDeleteModalOpen"
  [title]="deleteModalTitle"
  (modalClosed)="handleDelete()"
  (saveModalData)="closeDeleteModal()"
  [primaryButtonText]="'Cancel'"
  [secondaryButtonText]="'Confirm'"
>
  <div class="m-body">
    <div class="delete-modal-txt" [innerHtml]="deleteWarningText"></div>
  </div>
</app-modal>
