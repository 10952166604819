<footer class="footer" *ngIf="!isHidden">
  <ul class="desktop">
    <li>
      <a
        routerLink="/legal/terms-of-use"
        [innerHTML]="'footer.terms' | translate"
      >
        Uvjeti korištenja i kupnje
      </a>
    </li>
    <li></li>

    <!-- uncoment when enabeling cookeis -->
    <!-- <li>
      <a (click)="handleCookieSettingsClick($event)" class="cookies">
        Upravljanje kolačićima
      </a>
    </li>
    <li></li> -->
    <li>
      <a
        routerLink="/legal/privacy-policy"
        [innerHTML]="'footer.privacy' | translate"
      >
        Pravila privatnosti
      </a>
    </li>
    <li></li>
    <li class="footer__icons">
      <a href="https://www.facebook.com/pixsell.hr">
        <img src="/assets/footer/facebook.svg" alt="" />
      </a>
      <a href="https://www.instagram.com/pixsell_photo">
        <img src="/assets/footer/instagram.svg" alt="" />
      </a>
      <a href="https://twitter.com/pixsell">
        <img src="/assets/footer/twitter.svg" alt="" />
      </a>
      <a href="https://www.youtube.com/channel/UC44SlWK3qzMRywFlpGt5dmQ">
        <img src="/assets/footer/youtube.svg" alt="" />
      </a>
      <a href="https://www.linkedin.com/company/pixel-media-ltd.-zagreb/">
        <img src="/assets/footer/linkedin.svg" alt="" />
      </a>
    </li>
    <li></li>
    <li><span [innerHTML]="'footer.version' | translate"></span> {{version}}</li>
  </ul>
  <p class="desktop" [innerHTML]="'footer.footer' | translate">
    © 2025. by Pixel Media d.o.o. Design & technology:
    <span class="smart-code">Smart Code</span>
  </p>

  <!-- MOBILEEEEE -->
  <div class="mobile">
    <div>
      <div class="mobile-top">
        <div class="flexi-left">
          <a
            routerLink="/legal/terms-of-use"
            [innerHTML]="'footer.terms' | translate"
          >
            Uvjeti korištenja i kupnje
          </a>
        </div>
        <div class="mobile-vr"></div>
        <!--<div class="flexi-right">
          <a (click)="handleCookieSettingsClick($event)" class="cookies" [innerHTML]="'footer.redirect' | translate">
            Upravljanje kolačićima
          </a>
        </div>-->
      </div>

      <div class="mobile-middle">
        <div class="flexi-left">
          <a
            routerLink="/legal/privacy-policy"
            [innerHTML]="'footer.privacy' | translate"
          >
            Pravila privatnosti
          </a>
        </div>
        <div class="mobile-vr"></div>
        <div class="flexi-right">
          <div class="footer__icons">
            <a href="https://www.facebook.com/pixsell.hr">
              <img src="/assets/footer/facebook.svg" alt="" />
            </a>
            <a href="https://www.instagram.com/pixsell_photo">
              <img src="/assets/footer/instagram.svg" alt="" />
            </a>
            <a href="https://twitter.com/pixsell">
              <img src="/assets/footer/twitter.svg" alt="" />
            </a>
            <a href="https://www.youtube.com/channel/UC44SlWK3qzMRywFlpGt5dmQ">
              <img src="/assets/footer/youtube.svg" alt="" />
            </a>
            <a href="https://www.linkedin.com/company/pixel-media-ltd.-zagreb/">
              <img src="/assets/footer/linkedin.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <p class="mobile-bot" [innerHTML]="'footer.footer' | translate">
      © 2021. by Pixel Media d.o.o. Design & technology:&nbsp;
      <span class="smart-code">Smart Code</span>
    </p>
  </div>
</footer>
