import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AutocompleteOption } from '../../field-text/field-text.component';

export enum InputType {
  Text = 'text',
  Select = 'select',
  SelectNumeric = 'select_numeric',
  Autocomplete = 'autocomplete',
  Number = 'number',
  Boolean = 'boolean',
  Date = 'date',
  Password = 'password',
  Date2 = 'date2',
  Disabled = 'disabled',
  ImageUpload = 'img_upload',
  Tags = 'tags',
}

@Component({
  selector: 'app-transforming-input',
  templateUrl: './transforming-input.component.html',
  styleUrls: ['./transforming-input.component.scss'],
})
export class TransformingInputComponent implements OnInit {
  public InputType = InputType;

  @Input() autocompleteType: string = '';
  @Input() controlName: string = '';
  @Input() form!: FormGroup;
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() helpText?: string;
  @Input() type: InputType = InputType.Text;
  @Input() selectOptions: { [key: string]: any } = {};
  @Input() tags: AutocompleteOption[] = [];

  @Input() isEditModeActive: boolean = false;

  @Input() dateTimeFormat: string | undefined = 'dd.MM.yyyy. HH:mm';
  @Input() isDateFormated: boolean = true;

  constructor() {}

  ngOnInit(): void {}

  computeValue() {
    let values: AutocompleteOption[] =
      this.form.get(this.controlName)!.value ?? [];

    return values.map((value) => value.title).join(', ');
  }

  isBooleanTrue() {
    let value: string | boolean = this.form.get(this.controlName)?.value ?? false;

    if ( typeof value === 'string') {
      return value.toLowerCase() === 'true';
    } else {
      return value;
    }
  }
}
