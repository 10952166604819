import { Injectable } from '@angular/core';
import { PermissionsService } from './permissions.service';
import { User } from 'piwe-front-swagger-client';
import { filter } from 'rxjs/operators';

// Declare the global window object with the custom property
declare global {
  interface Window {
    initPixsellChatbot: (config: { API_URL: string }) => void;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ChatbotService {
  private scriptLoaded = false;

  constructor(private permissionsService: PermissionsService) {
    console.log('[ChatbotService] Service initialized');
    this.initChatbotForAdmins();
  }

  private initChatbotForAdmins(): void {
    console.log('[ChatbotService] Setting up permissions check for chatbot');
    // Subscribe to permissions data and wait for it to be loaded
    this.permissionsService.permissionsData
      .pipe(
        filter(permissions => permissions !== undefined)
      )
      .subscribe(permissions => {
        console.log('[ChatbotService] Permissions loaded:', permissions);
        
        // Only proceed if permissions include SUPERADMIN and script hasn't been loaded
        if (permissions && permissions.includes(User.UserPermissionsEnum.SUPERADMIN)) {
          console.log('[ChatbotService] User has SUPERADMIN permission');
          
          if (!this.scriptLoaded) {
            console.log('[ChatbotService] Loading chatbot script for admin user');
            this.loadChatbotScript();
          } else {
            console.log('[ChatbotService] Chatbot script already loaded');
          }
        } else {
          console.log('[ChatbotService] User does NOT have SUPERADMIN permission, skipping chatbot loading');
        }
      });
  }

  private loadChatbotScript(): void {
    // Check if script is already in the DOM
    if (document.querySelector('script[src="https://piwe-chat.smartcode.rocks/chat-assistant.js"]')) {
      console.log('[ChatbotService] Chatbot script already exists in DOM, skipping load');
      this.scriptLoaded = true;
      return;
    }

    // Mark as loaded first to prevent any double loading
    this.scriptLoaded = true;

    // Create and load the script
    const script = document.createElement('script');
    script.src = 'https://piwe-chat.smartcode.rocks/chat-assistant.js';
    script.async = true;

    // When the script loads, initialize the chatbot with configuration
    script.onload = function() {
      console.log('[ChatbotService] Chatbot script loaded successfully');
      
      // Check if the initialization function exists
      if (typeof window.initPixsellChatbot === 'function') {
        console.log('[ChatbotService] Initializing chatbot with API URL');
        window.initPixsellChatbot({
          API_URL: 'https://api-piwe-chat.smartcode.rocks'
        });
        
        // Check if chat-assistant element already exists to avoid duplicates
        if (!document.querySelector('chat-assistant')) {
          const chatElement = document.createElement('chat-assistant');
          document.body.appendChild(chatElement);
          console.log('[ChatbotService] chat-assistant element added to body');
        } else {
          console.log('[ChatbotService] chat-assistant element already exists, skipping creation');
        }
      } else {
        console.warn('[ChatbotService] initPixsellChatbot function not found in window');
      }
    };

    // Add error handling
    script.onerror = () => {
      console.error('[ChatbotService] Failed to load chatbot script');
      // Reset the flag so it might be attempted again if needed
      this.scriptLoaded = false;
    };

    // Add the script to the document
    document.head.appendChild(script);
    console.log('[ChatbotService] Pixsell chatbot script tag added to document head');
  }
}
