import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { IExtraFilters, ITags, ITagsArray } from './search';
import { SearchService, SubPortal } from './search.service';
import { FilterService } from './filter.service';
import { InfiniteScrollService } from '../infinite-scroll.service';
import { FilterRememberingService } from '../../front/filter-remembering.service';
import { AutocompleteOption } from '../field-text/field-text.component';
import logging from '../../logging';
import { ScrollService } from '../scroll.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit, AfterViewChecked
{
  constructor(
    private searchService: SearchService,
    private filterService: FilterService,
    private router: Router,
    private route: ActivatedRoute,
    private infiniteScrollService: InfiniteScrollService,
    private filterRemembering: FilterRememberingService,
    private scrollService: ScrollService
  ) {}

  get subPortalString(): string | undefined {
    switch (this.subPortal) {
      case SubPortal.Agencies:
        return 'AGENCIES';
      case SubPortal.Reuters:
        return 'REUTERS';
      case SubPortal.All:
        return 'ALL';
      case SubPortal.Stock:
        return 'STOCK';
      case SubPortal.Video:
        return 'VIDEO';
      case SubPortal.Production:
        return 'PRODUCTION';
      case SubPortal.Agephotostock:
        return 'AGEPHOTOSTOCK';
      case SubPortal.Halopix:
        return 'HALOPIX';
      default:
        return undefined;
    }
  }

  private readonly unsubscribe$ = new Subject<void>();

  public isFilterSidebarOpen = true;
  public isSearchActive = false;

  public searchResultNumber?: number = undefined;
  public searchResults: any[] = [];
  public searchQuery = '';

  public isAddToBasketOpen = false;
  public addToBasketId!: number;

  /**
   * Raw value of the filter applied.
   */
  public filterValue: any = {};
  public initialFilterValue?: any = undefined;

  displayValues: { [key: string]: string } = {
    'contentType.foto': 'Foto',
    'contentType.video': 'Video',
    'formats.vertical': 'Uspravno',
    'formats.horizontal': 'Položeno',
    'formats.square': 'Kvadrat',
    'colors.color': 'U boji',
    'colors.blackAndWhite': 'Crno-bijelo',
  };
  autocompleteValue?: AutocompleteOption | null;
  searchScrollPosition?: number;

  /**
   * The filter sidebar has some shared filters that are used across every search screen.
   * `To use extra ones`, pass them to this property using an `array` of strings.
   *
   * Supported values: `'agencies'` | `'photoJournalist'` | `'format'` | `'color'`.
   */
  @Input() extraFilters: IExtraFilters = [];
  @Input() bannerSrc = '';
  @Input() bannerTitle = '';
  @Input() bannerSearchPlaceholder = '';
  @Input() showContentType = true;
  @Input() showCategories = true;
  @Input() showTags = true;
  @Input() showTimeInterval = true;
  @Input() showMainFilters = false;
  @Input() showRefinementFilters = false;
  @Input() showDataOnlyOnHover = false;

  /**
   * Supported values: `'exclusive'` | `'topEvents'` | `'entertainment'` | `'editorsChoice'` | `'throughTime'` | `'portrait'` | `'specialOffer'` | `'galleryWithArticle'`;
   */
  @Input() tags: ITagsArray = [];

  @Input() subPortal: SubPortal = SubPortal.Production;
  public SubPortal: typeof SubPortal = SubPortal;

  public tagsArray: Array<any> = [];
  public isSearchDone = false;

  public showLoadNextPageButton = false;

  private previousScrollPosition = 0;
  @ViewChild('searchBar') searchBar!: ElementRef;
  public imagesPerRow = 4;

  public isMobile = false;

  @ViewChild('productionMain') productionMainRef: ElementRef | null = null;

  public hideMosaic = false;

  firstLoad = true;

  oldFilterValue: any = {};
  filterValueFirstLoad = true;

  ngOnInit() {
    //////////////////////////////////////////////////////////////////////////
    // INITIAL SETTING
    //////////////////////////////////////////////////////////////////////////
    this.searchResults = [];
    //////////////////////////////////////////////////////////////////////////

    this.tagsFactory();
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        logging.debug('search.component.ts', params);

        //////////////////////////////////////////////////////////////////////////
        // INITIAL SETTING
        //////////////////////////////////////////////////////////////////////////
        this.searchResults = [];
        //////////////////////////////////////////////////////////////////////////

        if (this.firstLoad) {
          // This will run on the first time this search component is loaded and
          // opened.
          if (this.filterRemembering.isRemembering) {
            logging.debug(
              'search.component.ts',
              'Restoring filter value and items'
            );

            if (this.filterRemembering.searchFilters != undefined) {
              logging.debug('search.component.ts', 'Restoring filter value');

              // Case when returning to search.
              this.displayValues = this.filterRemembering
                .searchFilterDisplayValues ?? {
                'contentType.foto': 'Foto',
                'contentType.video': 'Video',
                'formats.vertical': 'Uspravno',
                'formats.horizontal': 'Položeno',
                'formats.square': 'Kvadrat',
                'colors.color': 'U boji',
                'colors.blackAndWhite': 'Crno-bijelo',
              };
              this.autocompleteValue =
                this.filterRemembering.searchFilterAutocompleteValue;

              // This will not trigger filters did change case we check there if
              // value was changed.
              // this.oldFilterValue = this.filterRemembering.searchFilters;
              this.initialFilterValue = this.filterRemembering.searchFilters;
              this.filterValue = this.filterRemembering.searchFilters;
              logging.debug(
                'search.component.ts',
                'Setting filter value to',
                this.filterValue
              );
            }

            if (this.filterRemembering.searchResults != undefined) {
              logging.debug('search.component.ts', 'Restoring items');

              this.hideMosaic = true;
              this.searchResults = this.filterRemembering.searchResults;
              this.isSearchDone = true;
              this.searchScrollPosition =
                this.filterRemembering.searchScrollPosition;
              this.searchResultNumber =
                this.filterRemembering.searchNumberOfResults;
            }

            const q = params.q;
            this.searchQuery = q;
            this.isSearchActive = true;
            if (!q) {
              this.searchQuery = '';
              this.isSearchActive = false;
            }
          } else {
            logging.debug('search.component.ts', 'Doing normal search 1');
            this.checkWidthForMobile();
            // Case when first load.
            this.isSearchDone = false;
            const q = params.q;
            this.searchQuery = q;
            this.isSearchActive = true;
            if (!q) {
              this.searchQuery = '';
              this.isSearchActive = false;
            }

            try {
              logging.debug('search.component.ts', 'Checking URL parameters', params);
              
              // Check for orientation and kind in URL parameters
              if (params.orientation || params.kind) {
                logging.debug('search.component.ts', 'Found filter parameters in URL', {
                  orientation: params.orientation,
                  kind: params.kind
                });
                
                // Create base filter value structure
                const urlFilterValue = {
                  category: {},
                  timeInterval: { timeIntervalRadio: 'all', startDate: '', endDate: '' },
                  contentType: { content: '' },
                  tags: {},
                  agencies: {},
                  photoJournalist: { photoJournalist: '' },
                  formats: { format: '' },
                  colors: { color: '' },
                  refinementFilters: {}
                };

                // Set orientation if present
                if (params.orientation) {
                  try {
                    // Check for valid orientation values
                    const orientation = params.orientation.toLowerCase();
                    if (['horizontal', 'vertical', 'square'].includes(orientation)) {
                      urlFilterValue.formats.format = orientation;
                      logging.info('search.component.ts', '✅ Successfully applied orientation filter:', orientation);
                    } else {
                      logging.warn('search.component.ts', '⚠️ Ignored invalid orientation value:', orientation);
                    }
                  } catch (error) {
                    logging.error('search.component.ts', '❌ Error processing orientation parameter:', error);
                  }
                }

                // Set content type if present
                if (params.kind) {
                  try {
                    // Check for exact values to ensure correct mapping
                    const kind = params.kind.toLowerCase();
                    logging.debug('search.component.ts', 'Processing kind parameter:', kind);
                    
                    if (kind === 'video') {
                      urlFilterValue.contentType.content = 'video';
                      logging.info('search.component.ts', '✅ Successfully set content type to video');
                    } else if (kind === 'photo') {
                      urlFilterValue.contentType.content = 'foto';
                      logging.info('search.component.ts', '✅ Successfully set content type to foto');
                    } else {
                      logging.warn('search.component.ts', '⚠️ Unrecognized kind value:', kind);
                    }
                  } catch (error) {
                    logging.error('search.component.ts', '❌ Error processing kind parameter:', error);
                  }
                }

                this.initialFilterValue = urlFilterValue;
                this.filterValue = urlFilterValue;
                logging.info('search.component.ts', '✅ Successfully applied URL filter params', this.filterValue);
              } else {
                logging.debug('search.component.ts', 'No filter parameters found in URL');
              }
            } catch (error) {
              logging.error('search.component.ts', '❌ Error applying URL filter parameters:', error);
            }

            this.isSearchDone = false;
            this.setSearchResultNumber(undefined);
            this.rememberFiltersAndResults();
            if (this.subPortal == SubPortal.Agephotostock || this.isMobile) {
              this.searchService.search(
                this.subPortal,
                this.searchQuery,
                this.filterValue,
                false
              );
            }
          }
        } else {
          logging.debug('search.component.ts', 'Doing normal search 2');
          // Case when first load.
          this.isSearchDone = false;
          const q = params.q;
          this.searchQuery = q;
          this.isSearchActive = true;
          if (!q) {
            this.searchQuery = '';
            this.isSearchActive = false;
          }

          this.isSearchDone = false;
          this.setSearchResultNumber(undefined);
          this.rememberFiltersAndResults();
          this.searchService.search(
            this.subPortal,
            this.searchQuery,
            this.filterValue,
            false
          );
        }
        this.firstLoad = false;
      });

    this.searchService.searchResults$ = new Subject();
    this.searchService.searchResultNumber$ = new Subject();

    this.searchService.searchResults$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        logging.info('search.component.ts', 'searchResults change', data);
        this.setSearchResults(data);
        this.isSearchDone = true;

        this.rememberFiltersAndResults();
      });

    this.searchService.searchResultNumber$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((number: any) => {
        logging.info(
          'search.component.ts',
          'searchResultNumber change',
          number
        );
        this.setSearchResultNumber(number);

        this.rememberFiltersAndResults();
      });

    this.infiniteScrollService.scrollSignalData
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((_) => {
        const currentPage = this.searchService.currentPage ?? 1;
        if (currentPage < 2) {
          this.searchService.loadNextPage();
        } else {
          this.showLoadNextPageButton = true;
        }
      });

    this.filterRemembering.setScrollPositionData
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        if (!value.isAppComponent) {
          this.productionMainRef!.nativeElement!.scrollTo(0, value.position);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    // this overflow is related to filter sidebar, its used to disable the overflow when the sidebar is open
    document
      .querySelector('.main-app-container')!
      .classList.remove('disable-overflow');
  }

  ngAfterViewInit(): void {
    this.checkWidthForMobile();

    if (this.isMobile) {
      this.scrollService.scrollSignalData
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((response) => {
          this.updateSearchBarPositionOnScroll(response);
        });
    }
  }

  ngAfterViewChecked() {}

  atLeastOneLoaded($event: any) {
    setTimeout(() => {
      this.filterRemembering.scrollToRememberedPosition(this.isMobile);
      this.hideMosaic = false;
    }, 0);
  }

  isFilteringGallery(filterValue: any): boolean {
    if (filterValue.agencies) {
      const agnecies: object = filterValue.agencies;
      for (const agency in agnecies) {
        // @ts-ignore
        const value = agnecies[agency];
        if (value === true) {
          return true;
        }
      }
    }

    return false;
  }

  // gets triggered when the sidebar is open, so I'll drop this
  // @HostListener('window:resize', ['$event'])
  // onResize(event: any) {
  //   this.checkWidthForMobile();
  // }

  loadNextPage() {
    this.showLoadNextPageButton = false;
    this.searchService.loadNextPage();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.filterValue?.currentValue != undefined &&
      !changes.filterValue?.firstChange
    ) {
    }
  }

  filterValueChange() {
    // https://stackoverflow.com/questions/1068834/object-comparison-in-javascript
    const oldValueJson = JSON.stringify(this.initialFilterValue);
    const newValueJson = JSON.stringify(this.filterValue);

    logging.debug(
      'search.component.ts',
      'filterValueChange',
      oldValueJson,
      newValueJson
    );

    if (oldValueJson != newValueJson) {
      logging.debug(
        'search.component.ts',
        'Reloading filter cause old value is not equal to new',
        JSON.stringify(this.initialFilterValue),
        JSON.stringify(this.filterValue)
      );
      this.searchResults = [];
      this.isSearchDone = false;
      this.setSearchResultNumber(undefined);
      this.searchResults = [];
      this.searchService.search(
        this.subPortal,
        this.searchQuery,
        this.filterValue,
        false
      );
      this.productionMainRef!.nativeElement!.scrollTo(0, 0);
      this.rememberFiltersAndResults();
      this.filterValueFirstLoad = false;
      this.initialFilterValue = this.filterValue;
      logging.debug(
        'search.component.ts',
        'Setting filter value to 2',
        this.filterValue
      );
    } else {
      logging.debug(
        'search.component.ts',
        'Ignoring filter form value change',
        oldValueJson,
        newValueJson
      );
    }
  }

  filterDisplayValueChanged(value: any) {
    this.rememberFiltersAndResults();
  }

  filterAutocompleteValueChanged(value: any) {
    this.rememberFiltersAndResults();
  }

  openFilterSidebar() {
    this.isFilterSidebarOpen = true;
    if (this.isMobile) {
      // this overflow is related to filter sidebar, its used to disable the overflow when the sidebar is open
      document
        .querySelector('.main-app-container')!
        .classList.add('disable-overflow');
    }

    setTimeout(() => {
      this.makeDatePickerReadOnlyForMobile();
    }, 500);
  }

  closeFilterSidebar() {
    this.isFilterSidebarOpen = false;

    if (this.isMobile) {
      // this overflow is related to filter sidebar, its used to disable the overflow when the sidebar is open
      document
        .querySelector('.main-app-container')!
        .classList.remove('disable-overflow');
    }
  }

  searchResultsVisible(value: any) {
    this.isSearchActive = value;
  }

  rememberFiltersAndResults() {
    this.filterRemembering.remember(
      this.searchQuery,
      this.subPortalString,
      this.filterValue,
      this.displayValues,
      this.autocompleteValue,
      this.searchResults,
      this.searchResultNumber
    );
  }

  //////////////////////////////////////////////////////////////////////////////
  // RESULT MANAGEMENT
  //////////////////////////////////////////////////////////////////////////////

  setSearchResults(results: any[]) {
    this.searchResults = this.searchResults.concat(results);
  }

  setSearchResultNumber(number: number | undefined) {
    this.searchResultNumber = number;
  }

  //////////////////////////////////////////////////////////////////////////////

  tagsFactory() {
    const arr = this.tags.map((tag: ITags) => {
      switch (tag) {
        case 'exclusive':
          return {
            name: 'exclusive',
            text: 'Ekskluzivno',
            icon: 'star-tag.svg',
          };
        case 'topEvents':
          return {
            name: 'topEvents',
            text: 'Top događaji',
            icon: '',
          };

        case 'entertainment':
          return {
            name: 'entertainment',
            text: 'Zanimljivosti',
            icon: '',
          };

        case 'editorsChoice':
          return {
            name: 'editorsChoice',
            text: 'Izabrao urednik',
            icon: '',
          };

        case 'throughTime':
          return {
            name: 'throughTime',
            text: 'Vremeplov',
            icon: 'frame-tag.svg',
          };
        case 'portrait':
          return {
            name: 'portrait',
            text: 'Portret',
            icon: '',
          };
        case 'specialOffer':
          return {
            name: 'specialOffer',
            text: 'Posebna ponuda',
            icon: 'price-tag.svg',
          };
        case 'galleryWithArticle':
          return {
            name: 'galleryWithArticle',
            text: 'Galerija sa člankom',
            icon: '',
          };
        case 'girlOfTheDay':
          return {
            name: 'girlOfTheDay',
            text: 'Djevojka dana',
            icon: '',
          };

        default:
          return;
      }
    });
    this.tagsArray = arr;
  }

  closeAddToBasket() {
    this.isAddToBasketOpen = false;
  }

  addProductToBasket(id: number) {
    this.addToBasketId = id;
    this.isAddToBasketOpen = true;
  }

  //////////////////////////////////////////////////////////////////////////////
  // SCROLL
  //////////////////////////////////////////////////////////////////////////////

  onScroll() {
    // ismobile
    this.infiniteScrollService.signalScroll();
  }

  onNormalScroll(event: any) {
    const currentScrollPosition = event.target.scrollTop;
    this.searchScrollPosition = currentScrollPosition;
    this.filterRemembering.rememberScrollPosition(currentScrollPosition, false);
    this.updateSearchBarPositionOnScroll(currentScrollPosition);
  }

  updateSearchBarPositionOnScroll(scrollPosition: number) {
    const activateAt = this.isSearchActive ? 55 : 80;
    if (scrollPosition < activateAt) {
      this.searchBar.nativeElement.style.top = '62px';
    } else {
      if (this.previousScrollPosition > scrollPosition) {
        this.searchBar.nativeElement.style.top = '62px';
      } else {
        if (this.isMobile) {
          this.searchBar.nativeElement.style.top = '-53px';
        } else {
          this.searchBar.nativeElement.style.top = '-114px';
        }
      }
      this.previousScrollPosition = scrollPosition;
    }
  }

  checkWidthForMobile() {
    const width: number =
      document.querySelector('.main-app-container')?.clientWidth ?? 0;

    this.isMobile = false;
    if (width <= 1024) {
      this.isFilterSidebarOpen = false;
      this.isMobile = true;
    }
  }

  handleImagePerRowChange(n: number) {
    this.imagesPerRow = n;
  }

  makeDatePickerReadOnlyForMobile() {
    if (this.isMobile) {
      const datePicker = document.querySelectorAll('.dp-picker-input');
      // @ts-ignore
      datePicker[0]?.readOnly = true;
      // @ts-ignore
      datePicker[1]?.readOnly = true;
    }
  }
}
