import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FilterService } from '../filter.service';
import { interval, Observable, of, Subscription, zip } from 'rxjs';
import { IFilterData } from '../search';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  Agency,
  Category,
  SubportalFilterResponse,
} from '../../../../../projects/piwe-front-swagger-client/src';
import * as Tether from 'tether';
import { AutocompleteOption } from '../../field-text/field-text.component';
import {
  debounce,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
} from 'rxjs/operators';
import * as levenshtein from 'fast-levenshtein';
import {
  AutocompleteItem,
  AutocompleteService,
  MainFilter,
} from 'piwe-front-swagger-client';
import { SubPortal } from '../search.service';
import { IDatePickerConfig } from 'ng2-date-picker';
import logging from '../../../logging';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

const CATEGORY_ORDER: { [key: string]: number } = {
  Fashion: 5,
  News: 1,
  Show: 3,
  Sport: 2,
  Stock: 4,
};

const ALWAYS_OPEN_REFINEMENT_FILTERS = ['settings', 'technique'];

const ORDER_INDEX: { [key: string]: number } = {
  settings: 0,
  'only people (number of people)': 1,
  'only people (age)': 2,
  'only people (gender)': 3,
  'only people (ethnicity)': 4,
  'only people (model-released)': 5,
  technique: 6,
  viewpoint: 7,
  concept: 8,
  composition: 9,
  color: 10,
  'type of image': 11,
  'special collections': 12,
  resolution: 13,
};

@Component({
  selector: 'app-filter-sidebar',
  templateUrl: './filter-sidebar.component.html',
  styleUrls: ['./filter-sidebar.component.scss'],
})
export class FilterSidebarComponent implements OnInit, AfterViewInit {
  ALWAYS_OPEN_REFINEMENT_FILTERS = ALWAYS_OPEN_REFINEMENT_FILTERS;

  private formSubscription: Subscription = Subscription.EMPTY;
  private activeFiltersSubscription: Subscription = Subscription.EMPTY;
  public agencyArray: string[] = [];
  public activeFilters: {
    title: string;
    field: string;
    property: any;
    translationString?: string | null;
  }[] = [];

  @Input() extraFilters: Array<string> = [];
  @Output() closeFilterSidebarEvent: EventEmitter<void> = new EventEmitter();
  @Input() tagsArray: Array<any> = [];
  @Input() showContentType: boolean = true;
  @Input() showCategories: boolean = true;
  @Input() showTags: boolean = true;
  @Input() showTimeInterval: boolean = true;
  @Input() showMainFilters: boolean = false;
  @Input() showRefinementFilters: boolean = false;

  @Input() initialFilterValue?: any;
  @Input() filterValue: any = {};
  @Output() filterValueChange: EventEmitter<any> = new EventEmitter();

  refinementFiltersKeys: string[] = [];
  refinementFilters: {
    [key: string]: { title: string; id: number; translationString?: string }[];
  } = {};

  refinementFiltersCategoryTranslationKeys: {
    [key: string]: string;
  } = {};

  mainFilters: Array<MainFilter> = [];
  categories: Array<Category> = [];
  agenciesArr: Array<Agency> = [];
  @Input() subPortal: SubPortal = SubPortal.Production;
  isAgePhotoStock: boolean = false;

  subportalFilterResponse?: SubportalFilterResponse;

  @Input() displayValues: { [key: string]: string } = {
    'contentType.foto': 'Foto',
    'contentType.video': 'Video',
    'formats.vertical': 'Uspravno',
    'formats.horizontal': 'Položeno',
    'formats.square': 'Kvadrat',
    'colors.color': 'U boji',
    'colors.blackAndWhite': 'Crno-bijelo',
  };
  @Output() displayValuesChange: EventEmitter<{ [key: string]: string }> =
    new EventEmitter();

  tagSmallIcons: { [key: string]: string } = {
    Ekskluzivno: 'tag-exclusive.svg',
    Vremeplov: 'tag-timemachine.svg',
    'Posebna ponuda': 'tag-special-offer.svg',
  };

  config: IDatePickerConfig = {
    format: 'DD/MM/YYYY',
  };

  filterForm?: FormGroup;
  public areAllAgenciesVisible: boolean = false;
  private previousFilterFormValue?: any = undefined;

  public currentLanguage = 'hr';
  private currentLanguageSubscription = new Subscription();

  /** Keeps track of which filter used show more/less */
  public refinementFilterMask: boolean[] = [];

  constructor(
    private filterService: FilterService,
    private formBuilder: FormBuilder,
    private filtersService: FilterService,
    private autocompleteService: AutocompleteService,
    private translateService: TranslateService
  ) {}

  ///////////////////////////////
  // Autocomplete
  ///////////////////////////////

  tether: Tether | null = null;
  focusLostBecauseButton: boolean = false;

  autocompleteOptions: AutocompleteOption[] = [];
  autocompleteWidth: Number = 200.0;

  @ViewChild('autocomplete') autocomplete: ElementRef | null = null;

  @Input() autocompleteValue?: AutocompleteOption | null;
  @Output() autocompleteValueChange: EventEmitter<AutocompleteOption> =
    new EventEmitter<AutocompleteOption>();

  @Input() acceptUserInput: boolean = false;
  @Input() autocompleteType: string | undefined = 'author';

  @Input() minimalAutocompleteLength = 3;

  hideAutocomplete: boolean = false;

  @Input() value?: string = '';
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  // View children.
  @ViewChild('inputEl') protected inputEl: ElementRef | null = null;

  /**
   * Focus on input.
   */
  focus: boolean = false;

  get agencies() {
    return this.filterForm!.get('agencies') as FormGroup;
  }

  get tags() {
    return this.filterForm!.get('tags') as FormGroup;
  }

  get refinementFiltersElement() {
    return this.filterForm!.get('refinement-filters') as FormGroup;
  }

  ngOnInit(): void {
    this.isAgePhotoStock = this.subPortal == SubPortal.Agephotostock;

    if (this.isAgePhotoStock) {
      this.filtersService.loadFilter(this.subPortal).subscribe((f) => {
        this.mainFilters = f.available_main_filters!;

        const refinementFiltersGrouped = f.available_refinement_filters!.map(
          (rf) => {
            let section = rf.category;

            if (rf.subcategory != null) {
              section = section + ' (' + rf.subcategory + ')';
            }

            return {
              section: section,
              name: rf.name,
              id: rf.id,
              order: ORDER_INDEX[section.toLowerCase()] ?? 100,
            };
          }
        );

        let refinementFiltersDictionary: {
          [key: string]: { title: string; id: number }[];
        } = {};
        let keys: string[] = [];
        refinementFiltersGrouped.forEach((o) => {
          if (refinementFiltersDictionary[o.section] === undefined) {
            refinementFiltersDictionary[o.section] = [];
            keys.push(o.section);
          }

          refinementFiltersDictionary[o.section].push({
            title: o.name,
            id: o.id,
          });
        });

        this.refinementFiltersKeys = keys.sort((a, b) => {
          const orderA = ORDER_INDEX[a.toLowerCase()] ?? 100;
          const orderB = ORDER_INDEX[b.toLowerCase()] ?? 100;

          return orderA - orderB;
        });
        this.refinementFilters = refinementFiltersDictionary;
        this.initFormGroup();
        this.generateRefinementFilterTranslationString();
      });

      this.filtersService.loadSubPortal(this.subPortal);
    } else {
      this.filtersService
        .loadCategory(this.subPortal)
        .subscribe((categories) => {
          this.categories = categories!.sort((a, b) => {
            let aContent = a.name.find((t) => t.language == 'HR')!.content!;
            let bContent = b.name.find((t) => t.language == 'HR')!.content!;

            let aOrder = CATEGORY_ORDER[aContent]!;
            let bOrder = CATEGORY_ORDER[bContent]!;

            return aOrder - bOrder;
          });

          this.filtersService.loadFilter(this.subPortal).subscribe((f) => {
            this.tagsArray = f.available_filter_tags!.map((t) => {
              return {
                id: t.id,
                name: t.id.toString(),
                text: t.name.find((tag) => tag.language == 'HR')?.content,
                textEn: t.name.find((tag) => tag.language == 'EN')?.content,
                icon: this.tagSmallIcons[
                  t.name.find((tag) => tag.language == 'HR')!.content!
                ],
              };
            });

            //
            //agencies
            this.agenciesArr = f.available_filter_agency ?? [];

            // Server already sorts production.
            if (this.subPortal !== SubPortal.Production) {
              //sort the agencies array by name
              this.agenciesArr.sort((a, b) => {
                const nameA = a.name!.toUpperCase(); // ignore upper and lowercase
                const nameB = b.name!.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                // names must be equal
                return 0;
              });
            }

            //ABACA, DPA; Press Association, XINHUA, Newscom need to be the first 5
            // now added a 6th, reuters, this one is not always shown
            const firstFive = [
              'REUTERS',
              'Newscom',
              'XINHUA',
              'Press Association',
              'DPA',
              'ABACA',
            ];

            firstFive.map((name) => {
              this.agenciesArr.map((element, index) => {
                if (element.name === name) {
                  const objToBeMovedToStart = this.agenciesArr[index];
                  this.agenciesArr.splice(index, 1);
                  this.agenciesArr.splice(0, 0, objToBeMovedToStart);
                }
              });
            });

            this.initFormGroup();
          });
        });

      this.tagsArray.forEach((element: any) => {
        this.tags.addControl(element.name, this.formBuilder.control(false));
      });
    }

    //translation
    this.currentLanguage = this.translateService.currentLang;
    this.currentLanguageSubscription =
      this.translateService.onLangChange.subscribe(
        (params: LangChangeEvent) => {
          this.currentLanguage = params.lang;
        }
      );
  }

  private generateRefinementFilterMask(rfLen: number) {
    //keeps track of which filter used show more/less
    this.refinementFilterMask = new Array(rfLen);
    //new Array(n).fill(0); slower
    for (let i = 0; i < rfLen; ++i) this.refinementFilterMask[i] = false;
  }
  public toggleRefinementFilterMaskElement(n: number) {
    this.refinementFilterMask[n] = !this.refinementFilterMask[n];
  }

  ngAfterViewInit() {
    this.setUpAutocomplete();
  }

  showAllAgencies() {
    this.areAllAgenciesVisible = true;
  }

  showLessAgencies() {
    this.areAllAgenciesVisible = false;
  }

  public startDate: string = '';
  public endDate: string = '';

  changeStartDate(event: string) {
    if (event == undefined) {
      this.filterForm?.get('timeInterval.startDate')?.setValue('');
      return;
    }

    this.startDate = event;
    const tmp = this.startDate.split('/');
    const transformedDate = `${tmp[2]}-${tmp[1]}-${tmp[0]}`;
    this.filterForm?.get('timeInterval.startDate')?.setValue(transformedDate);
    if (
      this.filterForm?.get('timeInterval.timeIntervalRadio')?.value !=
      'customDate'
    ) {
      this.filterForm
        ?.get('timeInterval.timeIntervalRadio')
        ?.setValue('customDate');
    }
  }

  changeEndDate(event: string) {
    if (event == undefined) {
      this.filterForm?.get('timeInterval.endDate')?.setValue('');
      return;
    }

    this.endDate = event;
    const tmp = this.endDate.split('/');
    const transformedDate = `${tmp[2]}-${tmp[1]}-${tmp[0]}`;
    this.filterForm?.get('timeInterval.endDate')?.setValue(transformedDate);
    if (
      this.filterForm?.get('timeInterval.timeIntervalRadio')?.value !=
      'customDate'
    ) {
      this.filterForm
        ?.get('timeInterval.timeIntervalRadio')
        ?.setValue('customDate');
    }
  }
  resetStartDate() {
    this.startDate = '';
    this.filterForm?.get('timeInterval.startDate')?.setValue(undefined);
  }
  resetEndDate() {
    this.endDate = '';
    this.filterForm?.get('timeInterval.endDate')?.setValue(undefined);
  }
  initStartAndEndDate(sd: string, ed: string) {
    if (sd != undefined && sd != '') {
      const tmp = sd.split('-');
      this.startDate = `${tmp[2]}/${tmp[1]}/${tmp[0]}`;
    }
    if (ed != undefined && ed != '') {
      const tmp = ed.split('-');
      this.endDate = `${tmp[2]}/${tmp[1]}/${tmp[0]}`;
    }
  }

  initFormGroup() {
    try {
      logging.debug('filter-sidebar.component.ts', 'Initializing form group');
      
      let categoriesConfig: { [key: string]: [boolean] } = {};
      let refinementFiltersConfig: { [key: string]: [boolean] } = {};

      // Log counts to help debugging
      logging.debug('filter-sidebar.component.ts', 'Categories count:', this.categories?.length || 0);
      logging.debug('filter-sidebar.component.ts', 'Tags array count:', this.tagsArray?.length || 0);
      logging.debug('filter-sidebar.component.ts', 'Agencies count:', this.agenciesArr?.length || 0);

      // Add safety checks to avoid null reference errors
      if (this.categories && this.categories.length > 0) {
        for (const category of this.categories) {
          if (category && category.id != null) {
            this.displayValues['category.category-' + category.id.toString()] =
              category.name.find((c) => c.language == 'HR')?.content ?? '';
            categoriesConfig['category-' + category.id.toString()] = [false];
          }
        }
      }

      if (this.refinementFilters) {
        for (const key in this.refinementFilters) {
          const value = this.refinementFilters[key];
          if (value) {
            for (const filter of value) {
              if (filter && filter.id != null) {
                this.displayValues[
                  'refinementFilters.refinementFilter-' + filter.id.toString()
                ] = filter.title;
                refinementFiltersConfig['refinementFilter-' + filter.id.toString()] = [
                  false,
                ];
              }
            }
          }
        }
      }

      let tagsConfig: { [key: string]: [boolean] } = {};
      if (this.tagsArray && this.tagsArray.length > 0) {
        for (const tagsArrayElement of this.tagsArray) {
          if (tagsArrayElement && tagsArrayElement.id != null) {
            this.displayValues['tags.tag-' + tagsArrayElement.id] =
              tagsArrayElement.text;
            tagsConfig['tag-' + tagsArrayElement.name] = [false];
          }
        }
      }

      let agencyConfig: { [key: string]: [boolean] } = {};
      if (this.agenciesArr && this.agenciesArr.length > 0) {
        for (const agency of this.agenciesArr) {
          if (agency && agency.id != null) {
            this.displayValues['agencies.agency-' + agency.id] = agency.name ?? '';
            agencyConfig['agency-' + agency.id] = [false];
          }
        }
      }

      this.displayValuesChange.emit(this.displayValues);

      logging.debug('filter-sidebar.component.ts', 'Creating form group with configs', {
        categoriesConfigCount: Object.keys(categoriesConfig).length,
        tagsConfigCount: Object.keys(tagsConfig).length,
        agencyConfigCount: Object.keys(agencyConfig).length
      });

      this.filterForm = this.formBuilder.group({
        category: this.formBuilder.group(categoriesConfig),
        timeInterval: this.formBuilder.group({
          timeIntervalRadio: ['all'],
          startDate: [''],
          endDate: [''],
        }),
        contentType: this.formBuilder.group({
          content: [''],
        }),
        tags: this.formBuilder.group(tagsConfig),
        agencies: this.formBuilder.group(agencyConfig),
        photoJournalist: this.formBuilder.group({
          photoJournalist: [''],
        }),
        formats: this.formBuilder.group({
          format: [''],
        }),
        colors: this.formBuilder.group({
          color: [''],
        }),
        refinementFilters: this.formBuilder.group(refinementFiltersConfig),
      });
      
      logging.debug('filter-sidebar.component.ts', 'Form group initialized successfully');
    } catch (error) {
      logging.error('filter-sidebar.component.ts', 'Error initializing form group:', error);
      // Create a minimal form to prevent further errors
      this.filterForm = this.formBuilder.group({
        category: this.formBuilder.group({}),
        timeInterval: this.formBuilder.group({
          timeIntervalRadio: ['all'],
          startDate: [''],
          endDate: [''],
        }),
        contentType: this.formBuilder.group({
          content: [''],
        }),
        tags: this.formBuilder.group({}),
        agencies: this.formBuilder.group({}),
        photoJournalist: this.formBuilder.group({
          photoJournalist: [''],
        }),
        formats: this.formBuilder.group({
          format: [''],
        }),
        colors: this.formBuilder.group({
          color: [''],
        }),
        refinementFilters: this.formBuilder.group({}),
      });
    }

    // SUBSCRIPTION FOR EACH GROUP??
    try {
      this.formSubscription = this.filterForm!.valueChanges.subscribe(
        (data: IFilterData) => {
          try {
            const stringifiedData = JSON.stringify(data);
            if (stringifiedData !== this.previousFilterFormValue) {
              logging.debug(
                'filter-sidebar.component.ts',
                'Not ignoring filter form value change'
              );
              this.filterValueChange.emit(this.getFormRawValue());

              this.filterService.setFilters(data);
            } else {
              logging.debug(
                'filter-sidebar.component.ts',
                'Ignoring filter form value change',
                stringifiedData,
                this.previousFilterFormValue
              );
            }
            this.previousFilterFormValue = stringifiedData;
          } catch (error) {
            logging.error('filter-sidebar.component.ts', 'Error processing form value changes:', error);
          }
        }
      );
      
      this.activeFiltersSubscription =
        this.filterService.activeFilters$.subscribe((data) => {
          try {
            this.activeFilters = data.map((f) => {
              let title = this.displayValues[f.property];
              if (f.field == 'photoJournalist') {
                title = this.autocompleteValue?.title ?? '';
              }
              let translationString: string | null = null;
              if (f.field == 'refinementFilters') {
                const id = f.property.split('-')[1];
                translationString = 'refinement_filters.refinement_filter-' + id;
              }
              return { title, ...f, translationString };
            });
          } catch (error) {
            logging.error('filter-sidebar.component.ts', 'Error mapping active filters:', error);
            // Set empty array as fallback
            this.activeFilters = [];
          }
        });
        
      logging.debug('filter-sidebar.component.ts', 'Form subscriptions set up successfully');
    } catch (error) {
      logging.error('filter-sidebar.component.ts', 'Error setting up form subscriptions:', error);
    }

    if (
      this.initialFilterValue != undefined &&
      Object.keys(this.initialFilterValue).length > 0
    ) {
      try {
        logging.debug('filter-sidebar.component.ts', 'Setting initial filter values');
        
        this.initStartAndEndDate(
          this.initialFilterValue.timeInterval.startDate,
          this.initialFilterValue.timeInterval.endDate
        );
        
        logging.debug('filter-sidebar.component.ts', 'Setting form value to initial filter value', 
          Object.keys(this.initialFilterValue).length + ' properties');
          
        // This is where many errors happen - let's be extra careful
        try {
          this.filterForm.setValue(this.initialFilterValue);
          logging.debug('filter-sidebar.component.ts', '✅ Form value set successfully');
        } catch (error) {
          logging.error('filter-sidebar.component.ts', '❌ Error setting form value:', error);
          logging.debug('filter-sidebar.component.ts', 'Attempting to reconstruct initial value safely');
          
          // Try to create a minimal working version
          const safeInitialValue = {
            category: {},
            timeInterval: { 
              timeIntervalRadio: this.initialFilterValue.timeInterval?.timeIntervalRadio || 'all',
              startDate: this.initialFilterValue.timeInterval?.startDate || '',
              endDate: this.initialFilterValue.timeInterval?.endDate || ''
            },
            contentType: { 
              content: this.initialFilterValue.contentType?.content || '' 
            },
            tags: {},
            agencies: {},
            photoJournalist: { 
              photoJournalist: this.initialFilterValue.photoJournalist?.photoJournalist || '' 
            },
            formats: { 
              format: this.initialFilterValue.formats?.format || '' 
            },
            colors: { 
              color: this.initialFilterValue.colors?.color || '' 
            },
            refinementFilters: {}
          };
          
          try {
            this.filterForm.patchValue(safeInitialValue);
            logging.debug('filter-sidebar.component.ts', '✅ Form values patched successfully with safe values');
          } catch (patchError) {
            logging.error('filter-sidebar.component.ts', '❌ Even patching minimal values failed:', patchError);
          }
        }
        
        try {
          this.filterService.setFilters(this.initialFilterValue);
          logging.debug('filter-sidebar.component.ts', '✅ Filter service updated with initial values');
        } catch (filterError) {
          logging.error('filter-sidebar.component.ts', '❌ Error updating filter service:', filterError);
        }
      } catch (error) {
        logging.error('filter-sidebar.component.ts', '❌ Error applying initial filter values:', error);
      }
    }
  }

  generateRefinementFilterTranslationString() {
    for (const key in this.refinementFilters) {
      const value = this.refinementFilters[key];
      for (const filter of value) {
        //@ts-ignore
        filter.translationString =
          'refinement_filters.refinement_filter-' + filter.id.toString();
      }
    }

    this.refinementFiltersKeys.forEach((element) => {
      const catTlString = element
        .toString()
        .toLowerCase()
        .split(' ')
        .join('_')
        .split(')')
        .join('')
        .split('(')
        .join('');

      this.refinementFiltersCategoryTranslationKeys[element] =
        'refinement_filters.' + catTlString;
    });

    //List of all filters and their translation strings -> just copy paste into translation json
    /*console.log(
      'refinementFilters - all translation strings',
      this.refinementFiltersCategoryTranslationKeys,
      this.refinementFilters
    );*/
  }

  ngOnDestroy(): void {
    this.formSubscription.unsubscribe();
    this.activeFiltersSubscription.unsubscribe();

    this.currentLanguageSubscription.unsubscribe();
  }

  closeFilterSidebar() {
    this.closeFilterSidebarEvent.emit();
  }

  getFormRawValue() {
    return this.filterForm!.getRawValue();
  }

  removeActiveFilter(activeFilter: {
    title: string;
    field: string;
    property: any;
  }) {
    if (activeFilter?.field == 'formats') {
      this.filterForm?.get(activeFilter.field + '.format')?.setValue('');
      return;
    } else if (activeFilter?.field == 'colors') {
      this.filterForm?.get(activeFilter.field + '.color')?.setValue('');
      return;
    } else if (activeFilter?.field == 'contentType') {
      this.filterForm?.get(activeFilter.field + '.content')?.setValue('');
      return;
    } else if (activeFilter?.field == 'photoJournalist') {
      this.filterForm
        ?.get(activeFilter.field + '.photoJournalist')
        ?.setValue('');
      this.value = '';
      return;
    }

    const field = this.filterForm?.get(activeFilter.property);
    if (field) {
      field.setValue(false);
    }
  }

  ///////////////////////////////
  // Autocomplete
  ///////////////////////////////

  setUpAutocomplete() {
    this.valueChange
      .pipe(
        // Ignore any value change that is the same.
        distinctUntilChanged(),
        map((value) => {
          // Clear all autocomplete options on each character change.
          this.autocompleteOptions = [];
          return value;
        }),
        // API has no autocomplete if the query has less than 3 characters.
        filter((value) => {
          return value.length >= this.minimalAutocompleteLength;
        }),
        // Set the autocomplete option to loading...
        map((value) => {
          this.autocompleteOptions = [];

          if (this.acceptUserInput) {
            this.autocompleteOptions.push({
              value: -1,
              title: this.value!,
              isSelectable: true,
            });
          }

          this.autocompleteOptions.push({
            value: -1,
            title: 'Loading...',
            isSelectable: false,
          });

          return value;
        }),
        // Rate limit everything and ignore fast writers.
        debounce(() => interval(150)),
        // Merge the query value and the query result in one and use switch map.
        // Switch map is awesome because it will cancel the old query when
        // you start a new one.
        switchMap((value) => {
          return zip(of(value), this.getAutocompleteQuery(value)!);
        })
      )
      .subscribe((result) => {
        const [value, autocompleteOptions] = result;

        // We're doing out internal levenshtein sorting here.
        let sortedAutocompleteOption = autocompleteOptions
          .map((a) => {
            return { ...a, score: levenshtein.get(value, a.title) };
          })
          .sort((a, b) => a.score - b.score);

        // Remove more than 5 queries.
        if (sortedAutocompleteOption.length > 5) {
          sortedAutocompleteOption = sortedAutocompleteOption.slice(0, 5);
        }

        // Convert the results into out AutocompleteObject.
        let endResult: Array<AutocompleteOption> = [];
        let apiResult = sortedAutocompleteOption.map((option) => {
          let returnOption: AutocompleteOption = {
            title: option.title,
            value: option.id,
            isSelectable: true,
          };

          return returnOption;
        });

        if (
          this.acceptUserInput &&
          apiResult.find((a) => a.title == this.value) == undefined
        ) {
          endResult.push({ value: -1, title: this.value!, isSelectable: true });
        }

        endResult = endResult.concat(apiResult);

        this.autocompleteOptions = endResult;
      });
  }

  onAutocompleteClick(autocompleteOption: AutocompleteOption) {
    if (!autocompleteOption.isSelectable) {
      this.inputEl?.nativeElement.blur();
      return;
    }

    this.value = autocompleteOption.title;
    this.autocompleteValue = autocompleteOption;
    this.autocompleteValueChange.emit(this.autocompleteValue);

    this.filterForm!.get('photoJournalist.photoJournalist')!.setValue(
      this.autocompleteValue?.value
    );
    this.inputEl?.nativeElement.blur();
  }

  onAutocompleteToggleMouseDown() {
    this.focusLostBecauseButton = true;
    this.hideAutocomplete = !this.hideAutocomplete;
  }

  onAutocompleteToggleClick() {
    if (this.focus) return;

    this.inputEl?.nativeElement.focus();
    this.hideAutocomplete = false;
    this.onFocus();
  }

  getAutocompleteQuery(
    value: string
  ): Observable<AutocompleteItem[]> | undefined {
    return this.autocompleteService.frontGetAutocompleteAuthorsByName(value);
  }

  ///////////////////////////////
  // Event handling
  ///////////////////////////////

  onFocus() {
    this.tether = new Tether({
      target: this.inputEl!.nativeElement,
      element: this.autocomplete!.nativeElement,
      attachment: 'top left',
      targetAttachment: 'bottom left',
    });
    this.autocompleteWidth = this.inputEl!.nativeElement.offsetWidth;

    this.focus = true;
  }

  onBlur() {
    if (this.focusLostBecauseButton) {
      this.focusLostBecauseButton = false;
      this.inputEl?.nativeElement.focus();
      return;
    }

    this.focus = false;
    this.hideAutocomplete = false;

    this.tether?.destroy();
    this.tether = null;

    if (this.acceptUserInput && this.value != this.autocompleteValue?.title) {
      this.autocompleteValue = {
        title: this.value!,
        value: -1,
        isSelectable: false,
      };
      this.autocompleteValueChange.emit(this.autocompleteValue);
      this.filterForm!.get('photoJournalist.photoJournalist')!.setValue(
        this.autocompleteValue?.value
      );
    } else {
      if (!this.autocompleteValue) {
        this.value = '';
      } else if (this.value?.length == 0) {
        // If the user clears all content.
        this.value = '';
        this.autocompleteValue = undefined;
        this.autocompleteValueChange.emit(this.autocompleteValue);
        this.filterForm!.get('photoJournalist.photoJournalist')!.setValue(
          undefined
        );
      } else {
        this.value = this.autocompleteValue!.title;
      }
    }
  }

  onValueChanged() {
    this.valueChange.emit(this.value);
  }
}
