import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HomepageService as ApiHomepageService } from 'piwe-front-swagger-client';
import {
  ProductPreview,
  SubportalResponse,
} from '../../../../projects/piwe-front-swagger-client/src';
import * as moment from 'moment';
import { FancyGridComponent } from '../fancy-grid/fancy-grid.component';
import { takeUntil } from 'rxjs/operators';
import logging from '../../logging';

export enum SubPortal {
  Production,
  Video,
  Agencies,
  Reuters,
  Stock,
  Agephotostock,
  Halopix,
  All,
}

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private unsubscribe$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private homepageService: ApiHomepageService
  ) {}

  public searchResults$ = new Subject();
  public searchResultNumber$ = new Subject();

  currentSubPortal?: SubPortal;
  currentQuery?: string;
  currentFilterValue?: any;
  currentPage?: number;
  searchResultsByPage: Map<number, any> = new Map();

  public getSearchResults(
    subportal: SubPortal,
    query: string,
    filterValue: any,
    page: number,
    is_count: boolean
  ): Observable<SubportalResponse> {
    /*

    "photoJournalist": {
        "photoJournalist": "g"
    },
    }

     */
    let authorId: number | undefined;
    const rawJournalist: { [key: string]: string } | undefined =
      filterValue.photoJournalist;

    if (rawJournalist && rawJournalist.photoJournalist) {
      authorId = parseInt(rawJournalist.photoJournalist);
    }

    //

    let color: string | undefined;
    const rawColors: { [key: string]: string } | undefined = filterValue.colors;

    if (rawColors) {
      color = rawColors.color;

      switch (color) {
        case 'color':
          color = 'COLOUR';
          break;
        case 'blackAndWhite':
          color = 'GRAYSCALE';
          break;
        default:
          color = undefined;
      }
    }

    //

    let orientation: string | undefined;
    const rawFormats: { [key: string]: string } | undefined =
      filterValue.formats;

    if (rawFormats) {
      orientation = rawFormats.format;

      switch (orientation) {
        case 'vertical':
          orientation = 'VERTICAL';
          break;
        case 'horizontal':
          orientation = 'HORIZONTAL';
          break;
        case 'square':
          orientation = 'SQUARE';
          break;
        default:
          orientation = undefined;
      }
    }

    //

    let startDate: string | undefined;
    let endDate: string | undefined;
    let interval: string | undefined;

    const rawTimeInterval: { [key: string]: string } | undefined =
      filterValue.timeInterval;

    if (rawTimeInterval) {
      startDate = rawTimeInterval.startDate;
      endDate = rawTimeInterval.endDate;
      interval = rawTimeInterval.timeIntervalRadio;

      switch (interval) {
        case 'customDate':
          interval = 'RANGE';
          break;
        case '24h':
          interval = 'PAST_24H';
          break;
        case '48h':
          interval = 'PAST_48H';
          break;
        case '3M':
          interval = 'RANGE';
          startDate = moment().subtract(3, 'months').format('YYYY-MM-DD');
          endDate = moment().format('YYYY-MM-DD');
          break;
        default:
          interval = 'ALL';
      }
    }

    //

    let kind: string | undefined;
    const rawContentType: { [key: string]: string } | undefined =
      filterValue.contentType;

    if (rawContentType) {
      kind = rawContentType.content;

      switch (kind) {
        case 'video':
          kind = 'VIDEO';
          break;
        case 'foto':
          kind = 'PHOTO';
          break;
        default:
          kind = undefined;
      }
    }

    //

    let tags: number[] | undefined = [];
    const rawTagValues: { [key: string]: boolean } | undefined =
      filterValue.tags;
    if (rawTagValues) {
      for (const key of Object.keys(rawTagValues)) {
        const value = rawTagValues[key];

        if (value) {
          tags.push(parseInt(key.replace('tag-', '')));
        }
      }
    }

    if (tags?.length == 0) {
      tags = undefined;
    }

    //

    let agencies: number[] | undefined = [];
    const agenciesRawValues: { [key: string]: boolean } | undefined =
      filterValue.agencies;
    if (agenciesRawValues) {
      for (const key of Object.keys(agenciesRawValues)) {
        const value = agenciesRawValues[key];

        if (value) {
          agencies.push(parseInt(key.replace('agency-', '')));
        }
      }
    }

    if (agencies?.length == 0) {
      agencies = undefined;
    }

    //

    let categories: Array<number> | undefined = [];
    const rawCategoriesValues: { [key: string]: boolean } | undefined =
      filterValue.category;
    if (rawCategoriesValues) {
      for (const key of Object.keys(rawCategoriesValues)) {
        const value = rawCategoriesValues[key];

        if (value) {
          categories.push(parseInt(key.replace('category-', '')));
        }
      }
    }

    if (categories?.length == 0) {
      categories = undefined;
    }

    let searchQuery: string | undefined;
    if (query.length != 0) {
      searchQuery = query;
    }

    //

    let refinementFilters: Array<number> | undefined = [];
    const rawRefinementFiltersValues: { [key: string]: boolean } | undefined =
      filterValue.refinementFilters;
    if (rawRefinementFiltersValues) {
      for (const key of Object.keys(rawRefinementFiltersValues)) {
        const value = rawRefinementFiltersValues[key];

        if (value) {
          refinementFilters.push(
            parseInt(key.replace('refinementFilter-', ''))
          );
        }
      }
    }

    if (refinementFilters?.length == 0) {
      refinementFilters = undefined;
    }

    switch (subportal) {
      case SubPortal.Production:
        return this.homepageService.frontGetHomepageProduction(
          page - 1,
          50,
          searchQuery,
          categories,
          interval,
          startDate,
          endDate,
          tags,
          authorId,
          orientation,
          color,
          kind,
          agencies
        );
      case SubPortal.Video:
        return this.homepageService.frontGetHomepageVideo(
          page - 1,
          50,
          searchQuery,
          categories,
          interval,
          startDate,
          endDate,
          tags,
          authorId,
          agencies
        );
      case SubPortal.Agencies:
        return this.homepageService.frontGetHomepageAgencies(
          page - 1,
          50,
          searchQuery,
          categories,
          interval,
          startDate,
          endDate,
          tags,
          authorId,
          orientation,
          color,
          agencies,
          kind
        );
      case SubPortal.Reuters:
        return this.homepageService.frontGetHomepageReuters(
          page - 1,
          50,
          searchQuery,
          categories,
          interval,
          startDate,
          endDate,
          tags,
          authorId,
          orientation,
          color
        );
      case SubPortal.Stock:
        return this.homepageService.frontGetHomepageStock(
          page - 1,
          50,
          searchQuery,
          categories,
          interval,
          startDate,
          endDate,
          tags,
          authorId,
          orientation,
          color,
          kind
        );
      case SubPortal.Agephotostock:
        return this.homepageService.frontGetHomepageAgefotostock(
          page - 1,
          50,
          searchQuery,
          undefined,
          undefined,
          refinementFilters
        );
      case SubPortal.Halopix:
        return this.homepageService.frontGetHomepageAll(
          page - 1,
          50,
          searchQuery,
          categories,
          interval,
          startDate,
          endDate,
          tags,
          authorId,
          orientation,
          color,
          kind,
          [21]
        );
      case SubPortal.All:
        return this.homepageService.frontGetHomepageAll(
          page - 1,
          50,
          searchQuery,
          categories,
          interval,
          startDate,
          endDate,
          tags,
          authorId,
          orientation,
          color,
          kind,
          agencies
        );
    }
  }

  search(
    subportal: SubPortal,
    query: string,
    filterValue: any,
    is_count: boolean
  ) {
    this.currentSubPortal = subportal;
    this.currentQuery = query;
    this.currentFilterValue = filterValue;
    this.currentPage = 1;
    this.searchResultsByPage = new Map();

    this.unsubscribe$.complete();
    this.unsubscribe$ = new Subject();

    logging.debug(
      'search.service.ts',
      'search',
      subportal,
      query,
      filterValue,
      this.currentPage,
      is_count
    );

    this.getSearchResults(
      subportal,
      query,
      filterValue,
      this.currentPage,
      is_count
    )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        logging.debug(
          'search.service.ts',
          'search is done',
          subportal,
          query,
          filterValue,
          this.currentPage,
          is_count,
          value
        );

        this.searchResultsByPage.set(1, value.content);
        this.emitSearchResults(
          value.content!,
          value.total_results ?? 0,
          is_count
        );
      });
  }

  loadNextPage() {
    this.currentPage! += 1;
    const page = this.currentPage!;

    this.unsubscribe$.complete();
    this.unsubscribe$ = new Subject();

    this.getSearchResults(
      this.currentSubPortal!,
      this.currentQuery!,
      this.currentFilterValue!,
      this.currentPage!,
      false
    )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        this.searchResultsByPage.set(page, value.content);
        this.emitSearchResults(
          value.content!.map((i) => {
            i.id! = i.id!;
            return i;
          }),
          value.total_results ?? 0,
          false
        );
      });
  }

  emitSearchResults(
    productPreview: Array<ProductPreview>,
    totalResults: number,
    is_count: boolean
  ) {
    logging.debug(
      'search.service.ts',
      'emitSearchResults',
      this.searchResultsByPage
    );

    let results: any[] = [];
    const pageNumbers = this.searchResultsByPage.keys();

    for (const pageNumber of pageNumbers) {
      results = results.concat(this.searchResultsByPage.get(pageNumber));
    }

    this.searchResultNumber$.next(totalResults);

    // Fix God damn aspect ratio that server sends.
    this.searchResults$.next(
      productPreview.map((pp) => {
        if (pp.preview != undefined && pp.preview.length != 0) {
          pp.preview[0].aspect_ratio = FancyGridComponent.DEFAULT_ASPECT_RATIO;
        }

        return pp;
      })
    );
  }
}
